import React, { useEffect, useState } from "react";
import { Row, Col, Container, Tab, Nav, Button, Spinner } from "react-bootstrap";
import { Case } from "../../types/case";
import { fetchCases } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import ToastMsg from "../ui/ToastMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CaseTab from "./CaseTab";
import {
  faBriefcase,
  faHourglassStart,
  faPersonCircleQuestion,
  faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import CaseAssignmentTab from "./CaseAssignmentTab";
import useApp from "../../utils/useApp";
import { trackNavigationEvent } from "../../utils/analytics";
import useMenuToggle from "../../utils/useMenuToggle";

const Cases = () => {
  const { showMenu }: any = useApp();
  const toggleMenu = useMenuToggle();
  const [cases, setCases] = useState<Case[]>([]);
  const [activeCases, setActiveCases] = useState<Case[]>([]);
  const [waitlistCases, setWaitlistCases] = useState<Case[]>([]);
  const [inactiveCases, setInactiveCases] = useState<Case[]>([]);
  const [unassignedCases, setUnassignedCases] = useState<Case[]>([]);
  const [activeActive, setActiveActive] = useState<boolean>(false);
  const [activeWaitlist, setActiveWaitlist] = useState<boolean>(false);
  const [activeInactive, setActiveInactive] = useState<boolean>(false);
  const [activeUnassigned, setActiveUnassigned] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  useEffect(() => {
    setActiveActive(true);
    setActiveWaitlist(false);
    setActiveInactive(false);
    setActiveUnassigned(false);
    getCases();

    trackNavigationEvent("switch_view", "active_cases");
  }, []);

  useEffect(() => {
    setActiveCases(cases.filter((x: any) => x.casestatus === "active"));
    setWaitlistCases(cases.filter((x: any) => x.casestatus === "waitlist"));
    setInactiveCases(cases.filter((x: any) => x.casestatus === "inactive"));
    setUnassignedCases(cases.filter((x: any) => x.primaryCM === null || x.secondaryCM === null));
  }, [cases]);

  const getCases = () => {
    setIsLoading(true);

    fetchCases()
      .then((res: any) => {
        const c = res[0].data;
        setCases(c);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onNavClick = (route: string) => {
    if (route) {
      trackNavigationEvent("switch_view", route + "_cases");
    }

    switch (route) {
      case "active":
        setActiveActive(true);
        setActiveWaitlist(false);
        setActiveInactive(false);
        setActiveUnassigned(false);
        break;
      case "waitlist":
        setActiveActive(false);
        setActiveWaitlist(true);
        setActiveInactive(false);
        setActiveUnassigned(false);
        break;
      case "inactive":
        setActiveActive(false);
        setActiveWaitlist(false);
        setActiveInactive(true);
        setActiveUnassigned(false);
        break;
      case "unassigned":
        setActiveActive(false);
        setActiveWaitlist(false);
        setActiveInactive(false);
        setActiveUnassigned(true);
        break;
      default:
        setActiveActive(true);
        setActiveWaitlist(false);
        setActiveInactive(false);
        setActiveUnassigned(false);
        break;
    }
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Tab.Container defaultActiveKey={"active"}>
            <Row>
              <Col sm={3}>
                <Nav
                  variant="pills"
                  className={
                    showMenu
                      ? "flex-column sticky-top mycase-menu-open-sticky"
                      : "flex-column sticky-top mycase-menu-sticky"
                  }
                >
                  <Nav.Item className="mb-2">
                    <PageTitle title={toggleMenu ? "Cases (" + cases.length + ")" : "Cases"} />
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="active"
                      className={activeActive ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("active")}
                    >
                      <>
                        <FontAwesomeIcon icon={faBriefcase} className={"me-2"} />
                        {toggleMenu ? "Active (" + activeCases.length + ")" : "Active"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="waitlist"
                      className={activeWaitlist ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("waitlist")}
                    >
                      <>
                        <FontAwesomeIcon icon={faHourglassStart} className={"me-2"} />
                        {toggleMenu ? "Waitlist (" + waitlistCases.length + ")" : "Waitlist"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="inactive"
                      className={activeInactive ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("inactive")}
                    >
                      <>
                        <FontAwesomeIcon icon={faSquareXmark} className={"me-2"} />
                        {toggleMenu ? "Inactive (" + inactiveCases.length + ")" : "Inactive"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <hr />
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="unassigned"
                      className={activeUnassigned ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("unassigned")}
                    >
                      <>
                        <FontAwesomeIcon icon={faPersonCircleQuestion} className={"me-2"} />
                        {"Unassigned"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="active">
                    <CaseTab
                      cases={activeCases}
                      setCases={setCases}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="waitlist">
                    <CaseTab
                      cases={waitlistCases}
                      setCases={setCases}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="inactive">
                    <CaseTab
                      cases={inactiveCases}
                      setCases={setCases}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="unassigned">
                    <CaseAssignmentTab
                      cases={unassignedCases}
                      setCases={setCases}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      )}
    </>
  );
};

export default Cases;
