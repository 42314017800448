import React, { useState } from "react";
import FileUploadUI from "../../../ui/FileUploadUI";
import { postNoteDocument } from "../../../../services/httpClient";
import { useParams } from "react-router-dom";
import { Note } from "../../../../types/note";

interface FileUploadState {
  header: string;
  documents: any;
  setDocuments: any;
  note: Note | undefined;
  editNote: boolean;
}

const FileUploadNotes = ({ header, documents, setDocuments, note, editNote = false }: FileUploadState) => {
  const { id } = useParams();

  const [selectedFile, setSelectedFile]: any = useState();
  const [uploadIsEnabled, setUploadIsEnabled] = useState<boolean>(false);

  async function uploadFileData(data: any) {
    const document = {
      name: selectedFile.file.name,
      data: data,
    };

    setDocuments([...documents, document]);
    setSelectedFile("");
    setUploadIsEnabled(false);

    if (editNote) {
      postNoteDocument(selectedFile.file.name, data, id, note?.id).then((res: any) => {});
    }
  }

  return (
    <FileUploadUI
      header={header}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      uploadIsEnabled={uploadIsEnabled}
      setUploadIsEnabled={setUploadIsEnabled}
      uploadFileData={uploadFileData}
    />
  );
};

export default FileUploadNotes;
