import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { SingleCardMetric } from "../../../report-ui/SingleCardMetric";
import ReportTimeInterval from "../../../report-ui/ReportTimeInterval";
import { WellBeingLineChart } from "../../../report-ui/WellBeingLineChart";
import WellBeingQuestions from "../../../report-ui/WellBeingQuestions";
import ContinuumCategoryPicker from "../../../report-ui/ContinuumCategoryPicker";
import {
  fetchUserCaseReportGoals,
  fetchUserCaseReportInteractions,
  fetchUserCaseReportTasks,
  fetchUserCaseReportWellBeing,
} from "../../../../services/httpClient";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";

interface CaseReportsState {
  getData: boolean;
}

const CaseReports = ({ getData }: CaseReportsState) => {
  const { id } = useParams();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [dateInterval, setDateInterval] = useState<number>(365);
  const [interactionsTotal, setInteractionsTotal] = useState<number>(0);
  const [goalsTotal, setGoalsTotal] = useState<number>(0);
  const [tasksTotal, setTasksTotal] = useState<number>(0);
  const [assessOneActive, setAssessOneActive] = useState<boolean>(true);
  const [assessTwoActive, setAssessTwoActive] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>();
  const [satisfaction, setSatisfaction] = useState<any>();
  const [health, setHealth] = useState<any>();
  const [purpose, setPurpose] = useState<any>();
  const [virtue, setVirtue] = useState<any>();
  const [social, setSocial] = useState<any>();
  const [stability, setStability] = useState<any>();
  const [trend, setTrend] = useState<any>();
  const [trendByQuestion, setTrendByQuestion] = useState<any>();
  const [wellBeingData, setWellBeingData] = useState<any>();
  const [wellBeingTitle, setWellBeingTitle] = useState<string>("Average Well Being Trend");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      const start = new Date();
      start.setDate(start.getDate() - dateInterval);
      setStartDate(start.toISOString().slice(0, 10));
      setEndDate(new Date().toISOString().slice(0, 10));
      getReportData(start.toISOString().slice(0, 10), new Date().toISOString().slice(0, 10));
    }
  }, [getData]);

  const getReportData = async (start: string, end: string) => {
    await getInteractions(start, end);
    await getGoals(start, end);
    await getTasks(start, end);
    await getWellBeing();
    setIsLoading(false);
  };

  const getInteractions = async (start: string, end: string) => {
    await fetchUserCaseReportInteractions(start, end, id)
      .then((res: any) => {
        const interactions = res[0].data;
        setInteractionsTotal(interactions.length);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getGoals = async (start: string, end: string) => {
    await fetchUserCaseReportGoals(start, end, id)
      .then((res: any) => {
        const goals = res[0].data;
        setGoalsTotal(goals.length);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getTasks = async (start: string, end: string) => {
    await fetchUserCaseReportTasks(start, end, id)
      .then((res: any) => {
        const tasks = res[0].data;
        setTasksTotal(tasks.length);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getWellBeing = async () => {
    var s = new Date();
    s.setDate(s.getDate() - 10000);
    const start = s.toISOString().slice(0, 10);
    const end = new Date().toISOString().slice(0, 10);

    await fetchUserCaseReportWellBeing(start, end, id)
      .then((res: any) => {
        const well = res[0].data;
        calculateCategories(well);
        calculateWellBeingTrend(well);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const calculateCategories = (data: any) => {
    var runningValue = 0;
    //Happiness and Life Satisfaction
    var working = data.filter((i: any) => i.category === "Happiness and Life Satisfaction");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      var value = runningValue / working?.length;
      setSatisfaction(parseFloat(value.toFixed(1)));
    } else setSatisfaction("-");

    runningValue = 0;
    //Mental and Physical Health
    working = data.filter((i: any) => i.category === "Mental and Physical Health");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setHealth(parseFloat(value.toFixed(1)));
    } else setHealth("-");

    runningValue = 0;
    //Meaning and Purpose
    working = data.filter((i: any) => i.category === "Meaning and Purpose");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setPurpose(parseFloat(value.toFixed(1)));
    } else setPurpose("-");

    runningValue = 0;
    //Character and Virtue
    working = data.filter((i: any) => i.category === "Character and Virtue");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setVirtue(parseFloat(value.toFixed(1)));
    } else setVirtue("-");

    runningValue = 0;
    //Close Social Relationships
    working = data.filter((i: any) => i.category === "Close Social Relationships");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setSocial(parseFloat(value.toFixed(1)));
    } else setSocial("-");

    runningValue = 0;
    //Financial and Material Stability
    working = data.filter((i: any) => i.category === "Financial and Material Stability");
    if (working?.length > 0) {
      working?.map((w: any) => {
        runningValue = w?.value + runningValue;
      });
      value = runningValue / working?.length;
      setStability(parseFloat(value.toFixed(1)));
    } else setStability("-");
  };

  const calculateWellBeingTrend = (data: any[]) => {
    const groupedData: { [date: string]: { sum: number; count: number } } = data.reduce((acc: any, obj: any) => {
      const date = obj.created_at;
      const value = obj.value;
      if (!acc[date]) {
        acc[date] = { sum: 0, count: 0 };
      }
      acc[date].sum += value;
      acc[date].count++;
      return acc;
    }, {});

    // Calculate average for each group
    const averagedData = Object.entries(groupedData).map(([date, { sum, count }]) => ({
      date,
      score: sum / count,
    }));

    setTrend(averagedData);
    setQuestion("zero");

    // Example usage
    const groupedData2 = data.reduce((acc: any, obj: any) => {
      const key = obj.key;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push({ date: obj.created_at, score: obj.value });
      return acc;
    }, {});

    setTrendByQuestion(groupedData2);
  };

  useEffect(() => {
    switch (question) {
      case "one":
        setWellBeingData(trendByQuestion[1]);
        setWellBeingTitle("Question One Trend");
        break;
      case "two":
        setWellBeingData(trendByQuestion[2]);
        setWellBeingTitle("Question Two Trend");
        break;
      case "three":
        setWellBeingData(trendByQuestion[3]);
        setWellBeingTitle("Question Three Trend");
        break;
      case "four":
        setWellBeingData(trendByQuestion[4]);
        setWellBeingTitle("Question Four Trend");
        break;
      case "five":
        setWellBeingData(trendByQuestion[5]);
        setWellBeingTitle("Question Five Trend");
        break;
      case "six":
        setWellBeingData(trendByQuestion[6]);
        setWellBeingTitle("Question Six Trend");
        break;
      case "seven":
        setWellBeingData(trendByQuestion[7]);
        setWellBeingTitle("Question Seven Trend");
        break;
      case "eight":
        setWellBeingData(trendByQuestion[8]);
        setWellBeingTitle("Question Eight Trend");
        break;
      case "nine":
        setWellBeingData(trendByQuestion[9]);
        setWellBeingTitle("Question Nine Trend");
        break;
      case "ten":
        setWellBeingData(trendByQuestion[10]);
        setWellBeingTitle("Question Ten Trend");
        break;
      case "eleven":
        setWellBeingData(trendByQuestion[11]);
        setWellBeingTitle("Question Eleven Trend");
        break;
      case "twelve":
        setWellBeingData(trendByQuestion[12]);
        setWellBeingTitle("Question Twelve Trend");
        break;
      default:
        setWellBeingData(trend);
        setWellBeingTitle("Average Well Being Trend");
        break;
    }
  }, [question]);

  const updateAssessmentSelection = (type: string) => {
    switch (type) {
      case "wellbeing":
        setAssessOneActive(true);
        setAssessTwoActive(false);
        break;
      case "continuum":
        setAssessOneActive(false);
        setAssessTwoActive(true);
        break;
      default:
        setAssessOneActive(true);
        setAssessTwoActive(false);
        break;
    }
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <Row>
            <Col>
              <h4 className="mb-3 app-header-text">Interactions and Goals</h4>
            </Col>
          </Row>
          <ReportTimeInterval
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateInterval={dateInterval}
            setDateInterval={setDateInterval}
            intervalOne={30}
            intervalOneText={"30 Days"}
            intervalTwo={90}
            intervalTwoText={"90 Days"}
            intervalThree={180}
            intervalThreeText={"180 Days"}
            intervalFour={365}
            intervalFourText={"Last Year"}
            onClickRefresh={getReportData}
          />
          <Row className="mt-3">
            <Col>
              <SingleCardMetric title="Interactions" total={interactionsTotal} />
            </Col>
            <Col>
              <SingleCardMetric title="Goals Completed" total={goalsTotal} />
            </Col>
            <Col>
              <SingleCardMetric title="Tasks Completed" total={tasksTotal} />
            </Col>
          </Row>
          <hr />
          <Row className="mb-3">
            <Col>
              <h4 className="app-header-text">Assessments</h4>
            </Col>
            <Col className="col-3 d-grid">
              <Button
                className={assessOneActive ? "app-primary-color button" : "app-primary-color-inverse button"}
                onClick={() => updateAssessmentSelection("wellbeing")}
              >
                <FontAwesomeIcon icon={faClipboardQuestion} className={"me-2"} />
                {"Well Being"}{" "}
              </Button>
            </Col>
            <Col className="col-3 d-grid">
              <Button
                className={assessTwoActive ? "app-primary-color button" : "app-primary-color-inverse button"}
                onClick={(e) => updateAssessmentSelection("continuum")}
              >
                <FontAwesomeIcon icon={faClipboardCheck} className={"me-2"} />
                {"Continuum"}
              </Button>
            </Col>
            <Col></Col>
          </Row>
          {assessOneActive && (
            <>
              <Row className="justify-content-center gap-3 mb-3">
                <Col className="col-5">
                  <Card className="bg-light">
                    <Card.Body>
                      <Row className="text-center">
                        <Col className="col-9 d-flex align-items-center">
                          <h5 className="app-orange-text">{"Happiness and Life Satisfaction"}</h5>
                        </Col>
                        <Col className="col-3 d-flex align-items-center">
                          <h2 className="text-muted text-end">{satisfaction}</h2>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="col-5">
                  <Card className="bg-light">
                    <Card.Body>
                      <Row className="text-center">
                        <Col className="col-9 d-flex align-items-center">
                          <h5 className="app-orange-text">{"Mental and Physical Health"}</h5>
                        </Col>
                        <Col className="col-3 d-flex align-items-center">
                          <h2 className="text-muted text-end">{health}</h2>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="col-5">
                  <Card className="bg-light">
                    <Card.Body>
                      <Row className="text-center">
                        <Col className="col-9 d-flex align-items-center">
                          <h5 className="app-orange-text">{"Meaning and Purpose"}</h5>
                        </Col>
                        <Col className="col-3 d-flex align-items-center">
                          <h2 className="text-muted text-end">{purpose}</h2>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="col-5">
                  <Card className="bg-light">
                    <Card.Body>
                      <Row className="text-center">
                        <Col className="col-9 d-flex align-items-center">
                          <h5 className="app-orange-text">{"Character and Virtue"}</h5>
                        </Col>
                        <Col className="col-3 d-flex align-items-center">
                          <h2 className="text-muted text-end">{virtue}</h2>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="col-5">
                  <Card className="bg-light">
                    <Card.Body>
                      <Row className="text-center">
                        <Col className="col-9 d-flex align-items-center">
                          <h5 className="app-orange-text">{"Close Social Relationships"}</h5>
                        </Col>
                        <Col className="col-3 d-flex align-items-center">
                          <h2 className="text-muted text-end">{social}</h2>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="col-5">
                  <Card className="bg-light">
                    <Card.Body>
                      <Row className="text-center">
                        <Col className="col-9 d-flex align-items-center">
                          <h5 className="app-orange-text">{"Financial and Material Stability"}</h5>
                        </Col>
                        <Col className="col-3 d-flex align-items-center">
                          <h2 className="text-muted text-end">{stability}</h2>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <WellBeingQuestions question={question} setQuestion={setQuestion} />
              <Row className="mb-5">
                <Col></Col>
                <Col className="col-8">
                  <WellBeingLineChart assessData={wellBeingData} titleText={wellBeingTitle} />
                </Col>
                <Col></Col>
              </Row>
            </>
          )}
          {assessTwoActive && (
            <>
              <Row className="mb-5">
                <ContinuumCategoryPicker />
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CaseReports;
