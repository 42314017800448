import { faBan, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Col, Row, Stack, Image, Modal } from "react-bootstrap";

interface PeopleCardState {
  person: any;
}

const PeopleCard = ({ person }: PeopleCardState) => {
  const [show, setShow] = useState(false);
  const [currentImage, setCurrentImage] = useState<string>("");

  const handleImageClick = (image: string) => {
    setCurrentImage(image);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Card
        className={
          person?.is_banned
            ? "bg-light card-people card-danger mx-2 mt-3 p-3 button"
            : "bg-light card-people mx-2 mt-3 p-3 button"
        }
      >
        <Card.Body className="ms-2 p-0">
          <Row className="align-items-center h-100">
            {person?.profile[0]?.data && (
              <Col xs={"auto"} className="m-0 p-0">
                <Image
                  className="image-people mx-2 cursor-pointer"
                  src={"data:image/jpeg;base64, " + person?.profile[0]?.data}
                  onClick={() => handleImageClick("data:image/jpeg;base64, " + person?.profile[0]?.data)}
                />
              </Col>
            )}
            <Col xs={8} className="p-0">
              <h6 className={"my-0 app-header-text text-wrap first-letter text-truncate"}>{person.name}</h6>
              <p className="text-muted first-letter mb-0">{person.relationship}</p>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 text-end">
              {person?.is_banned === 1 && (
                <div className="position-absolute top-0 end-0">
                  <Stack direction="horizontal">
                    <small className="small-text app-danger-text me-1 mt-2 bg-light">Banned</small>
                    <FontAwesomeIcon icon={faBan} className="app-danger-text me-2 mt-2 bg-light" />
                  </Stack>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="col-12 text-end">
              {person.is_guardian === 1 &&
                (person.is_primary ? (
                  <div className="position-absolute bottom-0 end-0">
                    <Stack direction="horizontal">
                      <small className="small-text app-primary-color-inverse me-1 mb-2 bg-light">Primary</small>
                      <FontAwesomeIcon icon={faUserShield} className="app-primary-color-inverse me-2 mb-2 bg-light" />
                    </Stack>
                  </div>
                ) : (
                  <div className="position-absolute bottom-0 end-0">
                    <Stack direction="horizontal">
                      <text className="p-0 m-0 me-2 app-orange-text">
                        <small> </small>
                      </text>
                      <FontAwesomeIcon icon={faUserShield} className="app-primary-color-inverse me-2 mb-2" />
                    </Stack>
                  </div>
                ))}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* Lightbox Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={currentImage} fluid alt="Selected" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PeopleCard;
