import { useEffect, useState } from "react";

const useMenuToggle = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [width, setWidth] = useState(useWindowWidth());

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (width) {
      if (width <= 576) {
        setToggleMenu(true);
      } else if (width <= 992) {
        setToggleMenu(false);
      } else {
        setToggleMenu(true);
      }
    } else {
      setToggleMenu(true);
    }
  }, [width]);

  function useWindowWidth() {
    const [windowSize, setWindowSize] = useState<number>();

    useEffect(() => {
      function handleResize() {
        setWindowSize(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  return toggleMenu;
};

export default useMenuToggle;
