import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  deleteSupportsRelease,
  fetchSupportsReleaseDownload,
  patchSupportsData,
} from "../../../../services/httpClient";
import FormCheck from "../../../ui/FormCheckbox";
import FileUploadSupports from "./FileUploadSupports";
import ConfirmRemove from "./ConfirmRemove";
import { User } from "../../../../types/user";

interface EditSupportState {
  user: User | undefined;
  fetchSupports: any;
  setEditMember: any;
  setCurrentMember: any;
  release: any;
  setRelease: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  myCase: boolean;
}

const EditSupport = ({
  user,
  fetchSupports,
  setEditMember,
  setCurrentMember,
  release,
  setRelease,
  setShowToast,
  setToastText,
  setToastBody,
  myCase,
}: EditSupportState) => {
  const { id } = useParams();
  const [supportId, setSupportId] = useState<string | undefined>("");
  const [ifFileExists, setIfFileExists] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean | number | undefined>(false);
  const [isRemoving, setIsRemoving] = useState(false);

  // var supportTypes: string[] = ["Mentor", "Tutor", "Teacher", "Social Service Provider", "Other"];

  useEffect(() => {
    setSupportId(user?.id);
    setIfFileExists(release?.id ? true : false);
    setIsAuthorized(user?.is_authorized);
  }, [user]);

  const updateIsAuthorized = () => {
    const releaseSigned = {
      is_authorized: !isAuthorized,
    };

    patchSupportsData(releaseSigned, id, supportId).then((res: any) => {
      setIsAuthorized(!isAuthorized);
      fetchSupports();
      setShowToast(true);
      setToastText("Support Updated");
      setToastBody(user?.firstname + " " + user?.lastname);
    });
  };

  const onClickDownloadRelease = () => {
    fetchSupportsReleaseDownload(id, supportId).then((res: any) => {
      FileSaver.saveAs(res[0].data, release.name);
    });
  };

  const onClickDeleteRelease = () => {
    const releaseSigned = {
      is_authorized: 0,
    };

    deleteSupportsRelease(id, supportId).then((res: any) => {
      patchSupportsData(releaseSigned, id, supportId).then((res: any) => {
        setIsAuthorized(false);
        fetchSupports();
      });

      setEditMember(false);
      setCurrentMember(undefined);
    });
  };

  const onClickCancel = () => {
    setEditMember(false);
    setCurrentMember(undefined);
  };

  const onClickRemove = () => {
    setIsRemoving(true);
  };

  return (
    <>
      {isRemoving ? (
        <ConfirmRemove
          setIsRemoving={setIsRemoving}
          user={user}
          fetchSupports={fetchSupports}
          setEditMember={setEditMember}
          setCurrentMember={setCurrentMember}
        />
      ) : (
        <>
          <Row>
            <Col>
              <h4 className="app-header-text">Edit Support</h4>
            </Col>
            <Col className="text-end">
              <Stack className="stack-float" direction="horizontal" gap={3}>
                {myCase && (
                  <>
                    <Button className="app-danger-color button" onClick={() => onClickRemove()}>
                      Remove
                    </Button>
                    <div className="vr" />
                  </>
                )}
                <Button className="app-primary-color button" onClick={() => onClickCancel()}>
                  Cancel
                </Button>
              </Stack>
            </Col>
          </Row>
          {myCase && (
            <Row>
              <Col>
                <FormCheck label="Release Signed" value={isAuthorized} onUpdate={(e: any) => updateIsAuthorized()} />
              </Col>
            </Row>
          )}
          {ifFileExists && (
            <>
              <Col>
                <Stack direction="horizontal" gap={3}>
                  <Button className="app-tertiary-color button" onClick={() => onClickDownloadRelease()}>
                    <FontAwesomeIcon className="me-3" icon={faDownload} />
                    Release
                  </Button>
                  {myCase && (
                    <>
                      <div className="vr" />
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Delete</Tooltip>}
                      >
                        <Button className="me-3 app-danger-color button" onClick={() => onClickDeleteRelease()}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </OverlayTrigger>
                    </>
                  )}
                </Stack>
              </Col>
            </>
          )}
          {!ifFileExists && myCase && (
            <FileUploadSupports
              header="Upload Signed Release"
              setIfFileExists={setIfFileExists}
              setRelease={setRelease}
              userId={user?.id}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditSupport;
