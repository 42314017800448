import React, { useEffect, useRef } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";

interface SearchBarState {
  cssClass: any;
  placeholder: string;
  searchText: string;
  setSearchText: any;
  searchLogic: any;
  externalRef?: any;
}

const SearchBar = ({
  cssClass,
  placeholder = "Search",
  searchText,
  setSearchText,
  searchLogic,
  externalRef = null,
}: SearchBarState) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (externalRef) {
      externalRef?.current?.focus();
    } else {
      inputRef?.current?.focus();
    }
  }, []);

  const onSearch = (text: any) => {
    setSearchText(text);
    searchLogic(text);
  };

  return (
    <Form className={cssClass} onSubmit={(e) => e.preventDefault()}>
      <Form.Group as={Col}>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputGroup.Text>
          <Form.Control
            type="text"
            value={searchText}
            placeholder={placeholder}
            onChange={(e: any) => onSearch(e.target.value)}
            ref={externalRef ? externalRef : inputRef}
          />
          <InputGroup.Text onClick={() => onSearch("")} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faTimes} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default SearchBar;
