import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Stack, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  fetchEthnicities,
  fetchGenders,
  fetchRaces,
  fetchRelationships,
  patchGuardian,
} from "../../../../services/httpClient";
import DatePicker from "../../../ui/DatePicker";
import Input from "../../../ui/Input";
import InputPhone from "../../../ui/InputPhone";
import SelectObject from "../../../ui/SelectObject";
import ConfirmDelete from "./ConfirmDelete";
import FormCheck from "../../../ui/FormCheckbox";
import ConfirmBan from "./ConfirmBan";
import useApp from "../../../../utils/useApp";

interface EditGuardianState {
  user?: any;
  fetchHousehold: any;
  resetHouseholdTab: any;
  setUserToSave: any;
  guardians: any;
  onClickDoneEdit: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  myCase: boolean;
}

const EditGuardian = ({
  user,
  fetchHousehold,
  resetHouseholdTab,
  setUserToSave,
  guardians,
  onClickDoneEdit,
  setShowToast,
  setToastBody,
  setToastText,
  myCase,
}: EditGuardianState) => {
  const { id } = useParams();
  const { auth }: any = useApp();
  const [guardianId, setGuardianId] = useState<string>();
  const [firstName, setFirstName] = useState<string | undefined>("");
  const [lastName, setLastName] = useState<string>("");
  const [preferredName, setPreferredName] = useState<string>("");
  const [isPrimary, setIsPrimary] = useState<boolean>(false);
  const [isPrimaryUpdated, setIsPrimaryUpdated] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [relationship, setRelationship] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>();
  const [gender, setGender] = useState<string>("");
  const [race, setRace] = useState<string>("");
  const [ethnicity, setEthnicity] = useState<string>("");
  const [isBanned, setIsBanned] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string>("");
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [errMsg, setErrMsg] = useState("");
  const [relationships, setRelationships] = useState<any[]>([]);
  const [genders, setGenders] = useState<any[]>([]);
  const [races, setRaces] = useState<any[]>([]);
  const [ethnicities, setEthnicities] = useState<any[]>([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [guardianToDelete, setGuardianToDelete] = useState<string>();
  const [isBanning, setIsBanning] = useState(false);
  const [guardianToBan, setGuardianToBan] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const mainRef = useRef();

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    getRelationships();
    getGenders();
    getRaces();
    getEthnicities();
    handleScroll(mainRef);
  }, []);

  const getRelationships = () => {
    fetchRelationships().then((res: any) => {
      setRelationships(
        res[0].data.filter((c: any) => {
          return c.key !== "child";
        })
      );
    });
  };

  const getGenders = () => {
    fetchGenders().then((res: any) => {
      setGenders(res[0].data);
    });
  };

  const getRaces = () => {
    fetchRaces().then((res: any) => {
      setRaces(res[0].data);
    });
  };

  const getEthnicities = () => {
    fetchEthnicities().then((res: any) => {
      setEthnicities(res[0].data);
    });
  };

  useEffect(() => {
    setGuardianId(user?.id);
    setFirstName(user?.firstname);
    setLastName(user?.lastname);
    setPreferredName(user?.preferredname);
    setIsPrimary(user?.is_primary === 1 ? true : false);
    setEmail(user?.email);
    setPhone(user?.phonenumber === "" ? null : user?.phonenumber);
    setRelationship(user?.relationship);
    setBirthDate(user?.birthdate);
    setGender(user?.gender);
    setRace(user?.race);
    setEthnicity(user?.ethnicity);
    setIsBanned(user?.is_banned === 1 ? true : false);

    if (user?.profile.length > 0) {
      user?.profile?.map((p: any) => {
        const base64String = "data:image/jpeg;base64, " + p?.data;
        setProfileImage(base64String);
      });
    } else {
      setProfileImage("");
    }
  }, [user]);

  function validateEmail(email: string) {
    var regexp = /\S+@\S+\.\S+/;
    return regexp.test(email);
  }

  function validatePhone(phone: string) {
    let length = phone.length;
    return length === 11;
  }

  function enableSave() {
    if (email && phone && isValidEmail && isValidPhone) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    } else {
      setSaveIsEnabled(false);
      setUserToSave(false);
    }
  }

  const saveGuardian = () => {
    setErrMsg("");
    if (isValidEmail && isValidPhone) {
      //update others
      if (isPrimaryUpdated) {
        const otherGuardians = guardians.filter((g: any) => {
          return g.id !== guardianId;
        });

        const otherUpdatedGuardian = {
          is_primary: false,
        };

        otherGuardians.map((o: any) => {
          patchGuardian(otherUpdatedGuardian, id, o?.id)
            .then((res: any) => {})
            .catch((err: any) => {
              setErrMsg(err?.response?.data?.msg);
            });
        });
      }

      const updatedGuardian = {
        role: "family",
        firstname: firstName,
        lastname: lastName,
        preferredname: preferredName,
        is_primary: isPrimary,
        email: email,
        phonenumber: phone,
        relationship: relationship,
        birthdate: birthDate,
        gender: gender,
        race: race,
        ethnicity: ethnicity,
        is_guardian: true,
      };

      patchGuardian(updatedGuardian, id, guardianId)
        .then((res: any) => {
          setSaveIsEnabled(false);
          setUserToSave(false);
          fetchHousehold();
          setShowToast(true);
          const toast = preferredName ? firstName + " '" + preferredName + "' " + lastName : firstName + " " + lastName;
          setToastText("Guardian Updated");
          setToastBody(toast);
        })
        .catch((err: any) => {
          setErrMsg(err?.response?.data?.msg);
        });
    } else {
      setErrMsg("Email or Phone is in Invalid Format");
    }
  };

  const onClickDelete = () => {
    setIsDeleting(true);
    setGuardianToDelete(firstName + " " + lastName);
  };

  const updateFirstName = (e: any) => {
    setFirstName(e.target.value);
    enableSave();
  };

  const updateLastName = (e: any) => {
    setLastName(e.target.value);
    enableSave();
  };

  const updateEmail = (e: any) => {
    setEmail(e.target.value);

    let validEmail = validateEmail(e.target.value);
    if (validEmail) {
      setIsValidEmail(true);
      if (isValidPhone) {
        if (phone) {
          setSaveIsEnabled(true);
          setUserToSave(true);
        } else {
          setSaveIsEnabled(false);
          setUserToSave(false);
        }
      }
    } else {
      setIsValidEmail(false);
      setSaveIsEnabled(false);
      setUserToSave(false);
    }
  };

  const updatePhone = (e: any) => {
    setPhone(e);
    let validPhone = validatePhone(e);
    if (validPhone) {
      setIsValidPhone(true);
      if (isValidEmail) {
        if (email) {
          setSaveIsEnabled(true);
          setUserToSave(true);
        } else {
          setSaveIsEnabled(false);
          setUserToSave(false);
        }
      }
    } else {
      setIsValidPhone(false);
      setSaveIsEnabled(false);
      setUserToSave(false);
    }
  };

  const updatePreferredName = (e: any) => {
    setPreferredName(e.target.value);
    enableSave();
  };

  const updateIsPrimary = () => {
    if (!isPrimary) {
      setIsPrimaryUpdated(true);
    }
    setIsPrimary(!isPrimary);
    enableSave();
  };

  const updateRelationship = (e: any) => {
    setRelationship(e.target.value);
    enableSave();
  };

  const updateBirthDate = (e: any) => {
    setBirthDate(e);
    enableSave();
  };

  const updateGender = (e: any) => {
    setGender(e.target.value);
    enableSave();
  };

  const updateRace = (e: any) => {
    setRace(e.target.value);
    enableSave();
  };

  const updateEthnicity = (e: any) => {
    setEthnicity(e.target.value);
    enableSave();
  };

  const onClickSelect = (event: any) => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event: any) => {
    setErrMsg("");
    setIsImageLoading(true);

    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        // The file is an image; proceed with upload
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (reader.result) {
            uploadFileData(reader.result.toString().split(",")[1]);
          }
        };
        reader.onerror = function (error) {
          setIsImageLoading(false);
        };
      } else {
        // Not an image; display an error or ignore the file
        setErrMsg("Please select a valid image file");
        setIsImageLoading(false);
      }
    } else {
      setIsImageLoading(false);
    }
  };

  async function uploadFileData(data: any) {
    setProfileImage(data);

    var image;
    if (user?.profile_id) {
      image = {
        profile_id: user?.profile_id,
        profile_data: data,
      };
    } else {
      image = {
        profile_data: data,
      };
    }

    patchGuardian(image, id, guardianId)
      .then((res: any) => {
        fetchHousehold();

        const base64String = "data:image/jpeg;base64, " + data;
        setProfileImage(base64String);

        setShowToast(true);
        const toast = preferredName ? firstName + " '" + preferredName + "' " + lastName : firstName + " " + lastName;
        setToastText("Guardian Profile Image Updated");
        setToastBody(toast);
        setIsImageLoading(false);
      })
      .catch((err: any) => {
        setErrMsg(err?.response?.data?.msg);
        setIsImageLoading(false);
      });
  }

  const onClickBan = () => {
    setIsBanning(true);
    setGuardianToBan(firstName + " " + lastName);
  };

  return (
    <>
      {isDeleting ? (
        <ConfirmDelete
          userId={guardianId}
          setIsDeleting={setIsDeleting}
          userToDelete={guardianToDelete}
          setUserToDelete={setGuardianToDelete}
          fetchHousehold={fetchHousehold}
          resetHouseholdTab={resetHouseholdTab}
          type="Guardian"
          setShowToast={setShowToast}
          setToastText={setToastText}
          setToastBody={setToastBody}
        />
      ) : isBanning ? (
        <ConfirmBan
          user={user}
          isBanned={isBanned}
          setIsBanning={setIsBanning}
          userToBan={guardianToBan}
          setUserToBan={setGuardianToBan}
          fetchHousehold={fetchHousehold}
          resetHouseholdTab={resetHouseholdTab}
          type="Guardian"
          setShowToast={setShowToast}
          setToastText={setToastText}
          setToastBody={setToastBody}
        />
      ) : (
        <div>
          <Stack gap={3}>
            <Row>
              <Col>
                <h4 className="app-header-text">{"Edit Adult"}</h4>
              </Col>
              <Col className="text-end">
                <Stack className="stack-float" direction="horizontal" gap={3}>
                  {myCase && (
                    <>
                      <Button className="app-danger-color button" onClick={() => onClickDelete()}>
                        Delete
                      </Button>
                      <div className="vr" />
                      <Button
                        className="app-secondary-color button"
                        onClick={() => saveGuardian()}
                        disabled={!saveIsEnabled}
                      >
                        Save
                      </Button>
                      <div className="vr" />
                    </>
                  )}
                  <Button className="app-primary-color button" onClick={() => onClickDoneEdit()}>
                    Done
                  </Button>
                </Stack>
              </Col>
            </Row>
            <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
              {errMsg}
            </p>
            <Row className="h-auto">
              <Col className="col-9">
                <Stack gap={3}>
                  <Row>
                    <Col className="col-6">
                      <Input
                        label="First Name"
                        type="text"
                        value={firstName}
                        onUpdate={(e: any) => updateFirstName(e)}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                    <Col className="col-6">
                      <Input
                        label="Last Name"
                        type="text"
                        value={lastName}
                        onUpdate={(e: any) => updateLastName(e)}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6">
                      <Input
                        label="Email"
                        type="text"
                        value={email}
                        onUpdate={(e: any) => updateEmail(e)}
                        required={true}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                    <Col className="col-6">
                      <InputPhone
                        label="Phone"
                        value={phone}
                        onUpdate={(e: any) => updatePhone(e)}
                        required={true}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6">
                      <Input
                        label="Preferred Name"
                        type="text"
                        value={preferredName}
                        onUpdate={(e: any) => updatePreferredName(e)}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                    <Col className="col-6">
                      <DatePicker
                        label="Birth Date"
                        currentValue={birthDate}
                        setCurrentValue={updateBirthDate}
                        hasMaxDate={true}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6">
                      <SelectObject
                        label="Relationship"
                        value={relationship}
                        options={relationships}
                        onUpdate={(e: any) => updateRelationship(e)}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                    <Col className="col-6">
                      <SelectObject
                        label="Gender"
                        value={gender}
                        options={genders}
                        onUpdate={(e: any) => updateGender(e)}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6">
                      <SelectObject
                        label="Ethnicity"
                        value={ethnicity}
                        options={ethnicities}
                        onUpdate={(e: any) => updateEthnicity(e)}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                    <Col className="col-6">
                      <SelectObject
                        label="Race"
                        value={race}
                        options={races}
                        onUpdate={(e: any) => updateRace(e)}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6 text-start">
                      {(auth?.profile?.role === "org_admin" || auth?.profile?.role === "admin") && (
                        <Button className="app-tertiary-color-inverse button" onClick={() => onClickBan()}>
                          {isBanned ? "Unban" : "Ban"}
                        </Button>
                      )}
                    </Col>
                    <Col className="col-6">
                      <FormCheck
                        label="Primary"
                        value={isPrimary}
                        onUpdate={(e: any) => updateIsPrimary()}
                        disabled={myCase ? false : true}
                      />
                    </Col>
                  </Row>
                  <Row ref={mainRef}></Row>
                </Stack>
              </Col>
              <Col className="col-3">
                <Stack gap={3}>
                  {isImageLoading ? (
                    <Button size="sm" className="spinner-button spinner-button-main">
                      <Spinner animation="border" style={{ color: "#F6893D" }} />
                    </Button>
                  ) : (
                    <>
                      <Image src={profileImage} rounded className="image-profile" />
                      {myCase && (
                        <Button className="app-primary-color-inverse button" onClick={(e: any) => onClickSelect(e)}>
                          {profileImage ? "Change Image" : "Upload Image"}
                        </Button>
                      )}
                    </>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFileChange} // Handle the file change
                  />
                </Stack>
              </Col>
            </Row>
          </Stack>
        </div>
      )}
    </>
  );
};

export default EditGuardian;
