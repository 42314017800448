import React, { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, ListGroupItem, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { deleteTask, fetchGoals, fetchNextTasks, postGoal, postTask } from "../../../../services/httpClient";
import { Goal } from "../../../../types/goal";
import { Task } from "../../../../types/task";
import ToastMsg from "../../../ui/ToastMsg";
import GoalCard from "./GoalCard";
import TaskItem from "./TaskItem";
import { trackNavigationEvent } from "../../../../utils/analytics";
import CreateGoalCard from "../../../ui/CreateGoalCard";
import PageTitle from "../../../ui/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleDot } from "@fortawesome/free-solid-svg-icons";
import UseGoalTemplate from "./UseGoalTemplate";

interface GoalsState {
  viewGoals: boolean;
  setViewGoals: any;
  viewCompleted: boolean;
  setViewCompleted: any;
  viewArchive: boolean;
  setViewArchive: any;
  createGoal: boolean;
  setCreateGoal: any;
  myCase: boolean;
  getData: boolean;
}

const Goals = ({
  viewGoals,
  setViewGoals,
  viewCompleted,
  setViewCompleted,
  viewArchive,
  setViewArchive,
  createGoal,
  setCreateGoal,
  myCase,
  getData,
}: GoalsState) => {
  const { id } = useParams();
  const [currentGoals, setCurrentGoals] = useState<Goal[]>();
  const [completedGoals, setCompletedGoals] = useState<Goal[]>();
  const [archivedGoals, setArchivedGoals] = useState<Goal[]>();
  const [next, setNext] = useState<Task[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [goalId, setGoalId] = useState<string>("");
  const [goalSaved, setGoalSaved] = useState<boolean>(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [goalsNewActive, setGoalsNewActive] = useState<boolean>(true);
  const [goalsTemplateActive, setGoalsTemplateActive] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  const updateViewGoals = (e: any) => {
    setViewGoals(e);
    setViewCompleted(false);
    setViewArchive(false);
    setCreateGoal(false);
    getGoals();
    setGoalSaved(false);

    trackNavigationEvent("switch_view", "goals");
  };

  const updateViewCompleted = (e: any) => {
    setViewGoals(false);
    setViewCompleted(e);
    setViewArchive(false);
    setCreateGoal(false);

    trackNavigationEvent("switch_view", "completed_goals");
  };

  const updateViewArchive = (e: any) => {
    setViewGoals(false);
    setViewCompleted(false);
    setViewArchive(e);
    setCreateGoal(false);

    trackNavigationEvent("switch_view", "archived_goals");
  };

  const updateCreateGoal = (e: any) => {
    setViewGoals(false);
    setViewCompleted(false);
    setViewArchive(false);
    setCreateGoal(e);
  };

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getGoals();
    }
  }, [getData]);

  const getGoals = () => {
    fetchGoals(id)
      .then((res: any) => {
        const allGoals = res[0].data;

        const current = allGoals.filter((n: any) => n.is_completed === 0 && n.is_archived === 0);
        setCurrentGoals(current);
        console.log(current);
        fetchNextTasks(id)
          .then((res: any) => {
            const nextTasks = res[0].data;
            nextTasks.forEach((n: Task) => {
              if (n.next_at) {
                var nextDate = new Date(n.next_at);
                nextDate = new Date(nextDate.setDate(nextDate.getDate())); //subtract here to test diff
                nextDate.setHours(nextDate.getHours() - 5);
                const currentDate = new Date();
                const timeDiff = currentDate.getTime() - nextDate.getTime();
                const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
                n.age = daysDiff;
              }
            });

            setNext(nextTasks);
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });

        const completed = allGoals.filter((n: any) => n.is_completed === 1 && n.is_archived !== 1);
        setCompletedGoals(completed);

        const archive = allGoals.filter((n: any) => n.is_archived === 1);
        setArchivedGoals(archive);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onClickSaveGoal = (goalName: string) => {
    const newGoal = {
      name: goalName,
      is_completed: false,
      is_archived: false,
    };

    postGoal(newGoal, id).then((res: any) => {
      setGoalId(res[0].data.goal_id);
      setGoalSaved(true);
      setShowToast(true);
      setToastText("Goal Created");
      setToastBody(goalName);
    });
  };

  const onClickRemoveTask = (t: Task) => {
    deleteTask(id, goalId, t.id).then((res: any) => {
      setTasks((oldValues) => {
        return oldValues.filter((task) => task.name !== t.name);
      });
      setShowToast(true);
      setToastText("Task Deleted");
      setToastBody(t.name);
    });
  };

  const onClickSaveTask = (task: string) => {
    const newTask = {
      name: task,
      is_next: false,
      is_completed: false,
      is_cm_reviewed: false,
      is_archived: false,
    };

    postTask(newTask, id, goalId).then((res: any) => {
      const newTaskId = {
        id: res[0].data.task_id,
        name: task,
        is_next: false,
        is_completed: false,
        is_cm_reviewed: false,
        is_archived: false,
      };
      setTasks([...tasks, newTaskId]);
      setShowToast(true);
      setToastText("Task Created");
      setToastBody(task);
    });
  };

  const updateGoalSelection = (type: string) => {
    switch (type) {
      case "new":
        setGoalsNewActive(true);
        setGoalsTemplateActive(false);
        break;
      case "template":
        setGoalsNewActive(false);
        setGoalsTemplateActive(true);
        break;
      default:
        setGoalsNewActive(true);
        setGoalsTemplateActive(false);
        break;
    }
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          {viewGoals === true && (
            <>
              <Row className="mb-0">
                <Col className="col-5">
                  <h4 className="app-header-text">Current Goals</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-secondary-color me-3 button" onClick={() => updateViewCompleted(true)}>
                    Completed
                  </Button>
                  <Button className="app-secondary-color me-3 button" onClick={() => updateViewArchive(true)}>
                    Archived
                  </Button>
                  {myCase && (
                    <Button className="app-primary-color button" onClick={() => updateCreateGoal(true)}>
                      Create Goal
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                {currentGoals?.length === 0 ? (
                  <p className="ps-3">No Current Goals</p>
                ) : (
                  currentGoals?.map((g: Goal) => (
                    <GoalCard
                      key={g.id}
                      goal={g}
                      fetchGoals={getGoals}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                      myCase={myCase}
                    />
                  ))
                )}
              </Row>
              <Row>
                <Col>
                  <h4 className="app-header-text">Next Tasks</h4>
                </Col>
              </Row>
              <Row>
                <ListGroup className="pe-0 pb-5">
                  {next?.length === 0 ? (
                    <p className="ps-3">No Next Tasks</p>
                  ) : (
                    next?.map((n: any) => (
                      <ListGroupItem key={n.id}>
                        <TaskItem task={n} goalArchived={0} goalCompleted={0} inNextTasks={true} myCase={myCase} />
                      </ListGroupItem>
                    ))
                  )}
                </ListGroup>
              </Row>
            </>
          )}
          {viewCompleted === true && (
            <>
              <Row className="mb-0">
                <Col>
                  <h4 className="app-header-text">Completed Goals</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewGoals(true)}>
                    Back
                  </Button>
                </Col>
              </Row>
              <Row>
                {completedGoals?.length === 0 ? (
                  <p className="ps-3">No Completed Goals</p>
                ) : (
                  completedGoals?.map((a: any) => (
                    <GoalCard
                      key={a.id}
                      goal={a}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  ))
                )}
              </Row>
            </>
          )}
          {viewArchive === true && (
            <>
              <Row className="mb-0">
                <Col>
                  <h4 className="app-header-text">Archived Goals</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewGoals(true)}>
                    Back
                  </Button>
                </Col>
              </Row>
              <Row>
                {archivedGoals?.length === 0 ? (
                  <p className="ps-3">No Archived Goals</p>
                ) : (
                  archivedGoals?.map((a: any) => (
                    <GoalCard
                      key={a.id}
                      goal={a}
                      fetchGoals={getGoals}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  ))
                )}
              </Row>
            </>
          )}
          {createGoal === true && (
            <>
              <PageTitle
                title="Create Goal"
                type="section"
                buttonColor="primary"
                buttonTitle="Done"
                onButtonClick={() => updateViewGoals(true)}
              />
              <Row className="mb-3">
                <Col></Col>

                <Col className="col-3 d-grid">
                  <Button
                    className={goalsNewActive ? "app-primary-color button" : "app-primary-color-inverse button"}
                    onClick={() => updateGoalSelection("new")}
                  >
                    <FontAwesomeIcon icon={goalsNewActive ? faCircleCheck : faCircleDot} className={"me-2"} />
                    {"Enter New"}
                  </Button>
                </Col>
                <Col className="col-3 d-grid">
                  <Button
                    className={goalsTemplateActive ? "app-primary-color button" : "app-primary-color-inverse button"}
                    onClick={(e) => updateGoalSelection("template")}
                  >
                    <FontAwesomeIcon icon={goalsTemplateActive ? faCircleCheck : faCircleDot} className={"me-2"} />
                    {"Use Template"}
                  </Button>
                </Col>
                <Col></Col>
              </Row>
              {goalsNewActive && (
                <CreateGoalCard
                  onClickSaveGoal={onClickSaveGoal}
                  goalSaved={goalSaved}
                  setGoalSaved={setGoalSaved}
                  tasks={tasks}
                  setTasks={setTasks}
                  onClickRemoveTask={onClickRemoveTask}
                  onClickSaveTask={onClickSaveTask}
                />
              )}
              {goalsTemplateActive && (
                <UseGoalTemplate setShowToast={setShowToast} setToastText={setToastText} setToastBody={setToastBody} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Goals;
