import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { postNote, postNoteDocument, postSummary } from "../../../../services/httpClient";
import Select from "../../../ui/Select";
import Contact from "./Contact";
import Intervention from "./Intervention";
import Referral from "./Referral";
import Standard from "./Standard";
import StudentConnections from "./StudentConnections";
import useApp from "../../../../utils/useApp";
import { User } from "../../../../types/user";

interface CreateNoteState {
  fetchNotes: any;
  setViewNotes: any;
  setCreateNote: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  family: User[];
  saveIsEnabled: boolean;
  setSaveIsEnabled: any;
  setUserToSave: any;
  setSummary: any;
}

const CreateNote = ({
  fetchNotes,
  setViewNotes,
  setCreateNote,
  setShowToast,
  setToastText,
  setToastBody,
  family,
  saveIsEnabled,
  setSaveIsEnabled,
  setUserToSave,
  setSummary,
}: CreateNoteState) => {
  const { id } = useParams();
  const { showMenu }: any = useApp();
  const [type, setType] = useState<string>("");
  const [newNote, setNewNote] = useState<any>({});
  const [docs, setDocs] = useState<any[]>([]);

  var types: string[] = ["Standard", "Contact", "Intervention Follow-Up", "Referral", "Student Connections"];

  const updateType = (e: any) => {
    setType(e.target.value);
  };

  const onClickSave = () => {
    postNote(newNote, id).then((res: any) => {
      //Special code for Standard type notes
      const noteId = res[0].data.note_id;
      if (newNote.notetype === "standard" && docs.length > 0) {
        const length = docs.length;
        var counter = 0;
        docs.map((d: any) => {
          counter++;
          const name = d.name;
          const data = d.data;

          postNoteDocument(name, data, id, noteId).then((res: any) => {
            if (counter === length) {
              fetchNotes();
              setViewNotes(true);
              setCreateNote(false);
              setUserToSave(false);
              setShowToast(true);
              setToastText("Note Created");
              setToastBody(title(newNote.notetype));
            }
          });
        });
      } else {
        fetchNotes();
        setViewNotes(true);
        setCreateNote(false);
        setUserToSave(false);
        setShowToast(true);
        setToastText("Note Created");
        setToastBody(title(newNote.notetype));
      }

      postSummary(id)
        .then((res: any) => {
          var magic = res[0].data.summary.postResponse;

          setSummary(magic);
        })
        .catch((err: any) => {
          console.log(err);
        });
    });
  };

  const title = (type: any) => {
    if (type === "student") {
      return "Student Connections";
    } else if (type === "intervention") {
      return "Intervention Follow-Up";
    } else {
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
  };

  return (
    <>
      <Card
        className={showMenu ? "bg-light sticky-top card-note-menu-open-sticky" : "bg-light sticky-top card-note-sticky"}
      >
        <Row className="pe-0 mb-3 mt-3">
          <Col className="col-6">
            <Select label="Note Type" value={type} options={types} onUpdate={(e: any) => updateType(e)} />
          </Col>
          <Col className="col-4"></Col>
          <Col className="col-2 d-flex justify-content-end align-items-center">
            <Button className="app-secondary-color button" onClick={() => onClickSave()} disabled={!saveIsEnabled}>
              Save
            </Button>
          </Col>
        </Row>
      </Card>
      {type === "Standard" && (
        <Standard
          family={family}
          documents={docs}
          setDocuments={setDocs}
          setUpdatedNote={setNewNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={true}
        />
      )}
      {type === "Contact" && (
        <Contact
          family={family}
          setUpdatedNote={setNewNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={true}
        />
      )}
      {type === "Intervention Follow-Up" && (
        <Intervention
          family={family}
          setUpdatedNote={setNewNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={true}
        />
      )}
      {type === "Referral" && (
        <Referral
          family={family}
          setUpdatedNote={setNewNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={true}
        />
      )}
      {type === "Student Connections" && (
        <StudentConnections
          family={family}
          setUpdatedNote={setNewNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={true}
        />
      )}
    </>
  );
};

export default CreateNote;
