import React from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { deleteGuardian, deleteMember } from "../../../../services/httpClient";

interface DeleteState {
  userId: string | undefined;
  setIsDeleting: any;
  userToDelete: string | undefined;
  setUserToDelete: any;
  fetchHousehold: any;
  resetHouseholdTab: any;
  type: string;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const ConfirmDelete = ({
  userId,
  setIsDeleting,
  userToDelete,
  setUserToDelete,
  fetchHousehold,
  resetHouseholdTab,
  type,
  setShowToast,
  setToastText,
  setToastBody,
}: DeleteState) => {
  const { id } = useParams();

  const onClickDeleteFinal = () => {
    setIsDeleting(false);
    setUserToDelete("");

    if (type === "Guardian") {
      deleteGuardian(id, userId).then((res: any) => {
        resetHouseholdTab();
        fetchHousehold();
        setShowToast(true);
        setToastText("Guardian Deleted");
        setToastBody(userToDelete);
      });
    } else if (type === "Member") {
      deleteMember(id, userId).then((res: any) => {
        resetHouseholdTab();
        fetchHousehold();
        setShowToast(true);
        setToastText("Member Deleted");
        setToastBody(userToDelete);
      });
    }
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setUserToDelete("");
  };

  return (
    <Card className="bg-light mb-3">
      <Card.Body>
        <Stack direction="horizontal" gap={3}>
          <Stack style={{ justifyContent: "center" }}>
            <Card.Subtitle>Are you sure you want to delete {userToDelete}?</Card.Subtitle>
          </Stack>
          <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
            Confirm Delete
          </Button>
          <div className="vr" />
          <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
            Cancel
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default ConfirmDelete;
