import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { postAssessment } from "../../../../services/httpClient";
import LikertScale from "./LikertScale";

interface WellBeingState {
  setViewAssessments: any;
  setCreateAssessmentOne: any;
  setCreateAssessmentTwo: any;
  fetchAssessments: any;
  readOnly?: boolean;
  results?: any;
  setShowToast?: any;
  setToastText?: any;
  setToastBody?: any;
}

const AssessmentWellBeing = ({
  setViewAssessments,
  setCreateAssessmentOne,
  setCreateAssessmentTwo,
  fetchAssessments,
  readOnly = false,
  results,
  setShowToast,
  setToastText,
  setToastBody,
}: WellBeingState) => {
  const { id } = useParams();

  const [likertValue1, setLikertValue1] = useState();
  const [likertValue2, setLikertValue2] = useState();
  const [likertValue3, setLikertValue3] = useState();
  const [likertValue4, setLikertValue4] = useState();
  const [likertValue5, setLikertValue5] = useState();
  const [likertValue6, setLikertValue6] = useState();
  const [likertValue7, setLikertValue7] = useState();
  const [likertValue8, setLikertValue8] = useState();
  const [likertValue9, setLikertValue9] = useState();
  const [likertValue10, setLikertValue10] = useState();
  const [likertValue11, setLikertValue11] = useState();
  const [likertValue12, setLikertValue12] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const mainRef = useRef();

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    if (readOnly) {
      setLikertValue1(results.fields["1"]);
      setLikertValue2(results.fields["2"]);
      setLikertValue3(results.fields["3"]);
      setLikertValue4(results.fields["4"]);
      setLikertValue5(results.fields["5"]);
      setLikertValue6(results.fields["6"]);
      setLikertValue7(results.fields["7"]);
      setLikertValue8(results.fields["8"]);
      setLikertValue9(results.fields["9"]);
      setLikertValue10(results.fields["10"]);
      setLikertValue11(results.fields["11"]);
      setLikertValue12(results.fields["12"]);
    }

    handleScroll(mainRef);
  }, []);

  useEffect(() => {
    if (
      (likertValue1 || likertValue1 === 0) &&
      (likertValue2 || likertValue2 === 0) &&
      (likertValue3 || likertValue3 === 0) &&
      (likertValue4 || likertValue4 === 0) &&
      (likertValue5 || likertValue5 === 0) &&
      (likertValue6 || likertValue6 === 0) &&
      (likertValue7 || likertValue7 === 0) &&
      (likertValue8 || likertValue8 === 0) &&
      (likertValue9 || likertValue9 === 0) &&
      (likertValue10 || likertValue10 === 0) &&
      (likertValue11 || likertValue11 === 0) &&
      (likertValue12 || likertValue12 === 0)
    ) {
      setSubmitDisabled(false);
    }
  }, [
    likertValue1,
    likertValue2,
    likertValue3,
    likertValue4,
    likertValue5,
    likertValue6,
    likertValue7,
    likertValue8,
    likertValue9,
    likertValue10,
    likertValue11,
    likertValue12,
  ]);

  const onClickSubmit = () => {
    const assessSubmission = {
      assessmenttype: "well_being",
      fields: {
        "1": likertValue1,
        "2": likertValue2,
        "3": likertValue3,
        "4": likertValue4,
        "5": likertValue5,
        "6": likertValue6,
        "7": likertValue7,
        "8": likertValue8,
        "9": likertValue9,
        "10": likertValue10,
        "11": likertValue11,
        "12": likertValue12,
      },
    };

    postAssessment(assessSubmission, id).then((res: any) => {
      setViewAssessments(true);
      setCreateAssessmentOne(false);
      setCreateAssessmentTwo(false);
      fetchAssessments();
      setShowToast(true);
      setToastText("Assessment Created");
      setToastBody("Well Being");
    });
  };

  return (
    <>
      <Row ref={mainRef} className="my-0 py-0"></Row>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <Row>
              <h5 className="pb-2 app-header-text">
                <label className="app-orange-text">1.</label> Overall, how satisfied are you with life as a whole these
                days?
              </h5>
            </Row>
            <LikertScale
              value={likertValue1}
              setValue={setLikertValue1}
              beginText="Not Satisfied At All"
              endText="Completely Satisfied"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">2.</label> In general, how happy or unhappy do you usually feel?
            </h5>
            <LikertScale
              value={likertValue2}
              setValue={setLikertValue2}
              beginText="Extremely Unhappy"
              endText="Extremely Happy"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">3.</label> In general, how would you rate your physical health?
            </h5>
            <LikertScale
              value={likertValue3}
              setValue={setLikertValue3}
              beginText="Poor"
              endText="Excellent"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">4.</label> How would you rate your overall mental health?
            </h5>
            <LikertScale
              value={likertValue4}
              setValue={setLikertValue4}
              beginText="Poor"
              endText="Excellent"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">5.</label> Overall, to what extent do you feel the things you do in
              your life are worthwhile?
            </h5>
            <LikertScale
              value={likertValue5}
              setValue={setLikertValue5}
              beginText="Not At All Worthwhile"
              endText="Completely Worthwhile"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">6.</label> I understand my purpose in life.
            </h5>
            <LikertScale
              value={likertValue6}
              setValue={setLikertValue6}
              beginText="Strongly Disagree"
              endText="Strongly Agree"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">7.</label> I always act to promote good in all circumstances, even in
              difficult and challenging situations.
            </h5>
            <LikertScale
              value={likertValue7}
              setValue={setLikertValue7}
              beginText="Not True of Me"
              endText="Completely True of Me"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">8.</label> I am always able to give up some happiness now for greater
              happiness later.
            </h5>
            <LikertScale
              value={likertValue8}
              setValue={setLikertValue8}
              beginText="Not True of Me"
              endText="Completely True of Me"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">9.</label> I am content with my friendships and relationships.
            </h5>
            <LikertScale
              value={likertValue9}
              setValue={setLikertValue9}
              beginText="Strongly Disagree"
              endText="Strongly Agree"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">10.</label> My relationships are as satisfying as I would want them to
              be.
            </h5>
            <LikertScale
              value={likertValue10}
              setValue={setLikertValue10}
              beginText="Strongly Disagree"
              endText="Strongly Agree"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">11.</label> How often do you worry about being able to meet normal
              monthly living expenses?
            </h5>
            <LikertScale
              value={likertValue11}
              setValue={setLikertValue11}
              beginText="Worry All of the Time"
              endText="Do Not Ever Worry"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="bg-light">
        <Card.Body>
          <Row>
            <h5 className="pb-2 app-header-text">
              <label className="app-orange-text">12.</label> How often do you worry about safety, food, or housing?
            </h5>
            <LikertScale
              value={likertValue12}
              setValue={setLikertValue12}
              beginText="Worry All of the Time"
              endText="Do Not Ever Worry"
              readOnly={readOnly}
            />
          </Row>
        </Card.Body>
      </Card>
      {!readOnly ? (
        <Row className="text-center pb-5">
          <Col></Col>
          <Col className="col-4">
            <Button className="app-secondary-color button" disabled={submitDisabled} onClick={() => onClickSubmit()}>
              Submit Results
            </Button>
          </Col>
          <Col></Col>
        </Row>
      ) : (
        <Row className="pb-5"></Row>
      )}
    </>
  );
};

export default AssessmentWellBeing;
