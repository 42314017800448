import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/authentication/Login";
import RequireAuth from "./components/ui/RequireAuth";
import SendPasswordReset from "./components/authentication/SendPasswordReset";
import PasswordResetSent from "./components/authentication/PasswordResetSent";
import PasswordReset from "./components/authentication/PasswordReset";
import MyCases from "./components/my-cases/MyCases";
import Users from "./components/users/Users";
import Cases from "./components/cases/Cases";
import MyCase from "./components/my-cases/my-case/MyCase";
import EditUser from "./components/users/EditUser";
import EditCase from "./components/cases/EditCase";
import Reports from "./components/reports/Reports";
import MyCaseSupport from "./components/my-cases/my-case/MyCaseSupport";
import Authenticate from "./components/authentication/Authenticate";
import RequireAuth2fa from "./components/ui/RequireAuth2fa";
import TwoFactorSetup from "./components/authentication/TwoFactorSetup";
import InternalLogin from "./components/authentication/InternalLogin";
import Announcements from "./components/announcements/Announcements";
import EditAnnouncement from "./components/announcements/EditAnnouncement";
import EditGroup from "./components/groups/EditGroup";
import GroupMeeting from "./components/groups/GroupMeeting";
import AllGroups from "./components/groups/AllGroups";
import { signal } from "@preact/signals-react";
import useApp from "./utils/useApp";
import { clarity } from "react-microsoft-clarity";
import { logout } from "./services/httpClient";
import Settings from "./components/settings/Settings";
import ToastMsgButton from "./components/ui/ToastMsgButton";

export const unsavedChanges = signal(false);
export const currentUrl = signal("/");

const App = () => {
  const { auth, setActivePage }: any = useApp();
  const [showToast, setShowToast] = useState<boolean>(false);

  let navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = (event: BeforeUnloadEvent) => {
      if (unsavedChanges.value === true) {
        event.preventDefault();
        unsavedChanges.value = false;
      }

      if (document.activeElement instanceof HTMLElement && typeof document.activeElement.blur === "function") {
        document.activeElement.blur();
      }
      setActivePage(window.location.pathname);
    };

    window.addEventListener("popstate", handleBackButton);

    //Configure Microsoft Clarity
    if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
      clarity.init("oj2ivcloxk");
    }

    //Check if Auth Token Expired
    const checkTokenExpiration = () => {
      const expirationTime = localStorage.getItem("expiration");
      if (expirationTime && new Date(expirationTime) <= new Date()) {
        // Token expired
        logout();
        navigate("/login");
      }
    };

    //check every 10 minutes
    const intervalId = setInterval(checkTokenExpiration, 600000);

    const checkVersion = async () => {
      const res = await fetch("/version.txt");
      const currentVersion = await res.text();
      if (currentVersion !== localStorage.getItem("appVersion")) {
        localStorage.setItem("appVersion", currentVersion);
        setShowToast(true);
      }
    };

    //check every minute should change to 10 minutes
    const versionInterval = setInterval(checkVersion, 600000);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
      clearInterval(intervalId);
      clearInterval(versionInterval);
    };
  }, []);

  return (
    <>
      <ToastMsgButton showToast={showToast} setShowToast={setShowToast} />

      <Routes>
        <Route element={<RequireAuth />}>
          <Route path="/authenticate" element={<Authenticate />} />
          <Route path="/twofactor-setup" element={<TwoFactorSetup />} />
        </Route>

        <Route element={<RequireAuth2fa />}>
          <Route path="/" element={auth?.profile?.role === "org_admin" ? <Settings /> : <MyCases />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/my-cases" element={<MyCases />} />
          <Route path="/my-cases/:id" element={auth?.profile?.role === "support" ? <MyCaseSupport /> : <MyCase />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/user" element={<EditUser />} />
          <Route path="/users/user/:id" element={<EditUser />} />
          <Route path="/users/user/:id?profile=true" element={<EditUser />} />
          <Route path="/cases" element={<Cases />} />
          <Route path="/cases/case" element={<EditCase />} />
          <Route path="/cases/case/:id" element={<EditCase />} />
          <Route path="/groups" element={<AllGroups />} />
          <Route path="/groups/group" element={<EditGroup />} />
          <Route path="/groups/group/:id" element={<EditGroup />} />
          <Route path="/groups/group/:id/meeting" element={<GroupMeeting />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/announcements/announcement" element={<EditAnnouncement />} />
          <Route path="/announcements/announcement/:id" element={<EditAnnouncement />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/send-reset" element={<SendPasswordReset />} />
        <Route path="/reset-sent" element={<PasswordResetSent />} />
        <Route path="/reset" element={<PasswordReset mode="desktop" />} />
        <Route path="/reset-mobile" element={<PasswordReset mode="mobile" />} />
        <Route path="/internal-login" element={<InternalLogin />} />

        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default App;

const Error = () => (
  <div>
    <h2>Error</h2>
  </div>
);
