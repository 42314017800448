import { faEdit, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Note } from "../../../../types/note";

interface InterventionViewState {
  n: Note;
  setEdit: any;
  setCurrent: any;
}

const InterventionView = ({ n, setEdit, setCurrent }: InterventionViewState) => {
  const onClickEdit = () => {
    setEdit(true);
    setCurrent(n);
  };

  return (
    <Card className="bg-light mb-3 cursor-pointer" onClick={(e) => onClickEdit()}>
      <Card.Body>
        <>
          <Row>
            <Col>
              <h5 className="app-orange-text">Intervention Follow-Up</h5>
            </Col>
            <Col className="text-end">
              <label className="note-type text-muted me-3">{n.fields.date}</label>
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Edit Note</Tooltip>}>
                <FontAwesomeIcon className="text-muted cursor-pointer" icon={faEdit} onClick={(e) => onClickEdit()} />
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            {n.fields.who_completed && (
              <h6>
                Referral Completed by {n.fields.who_completed.charAt(0).toUpperCase() + n.fields.who_completed.slice(1)}
              </h6>
            )}
            {n.fields.who_referred_to && <p className="mb-0">Client Referred to {n.fields.who_referred_to}</p>}
          </Row>
          <Row className="mt-1">
            <Col className="col-6 text-start">
              <Badge bg="secondary" className="me-3">
                Created By {n.firstname} {n.lastname}
              </Badge>
              {n?.recordingsExist === true && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Recordings Exist</Tooltip>}
                >
                  <FontAwesomeIcon className="text-muted" icon={faMicrophone} />
                </OverlayTrigger>
              )}
            </Col>
            <Col className="col-6 text-end">
              <Badge bg="secondary">{n.subject?.name}</Badge>
            </Col>
          </Row>
        </>
      </Card.Body>
    </Card>
  );
};

export default InterventionView;
