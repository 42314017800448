import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchDocuments } from "../../../../services/httpClient";
import ToastMsg from "../../../ui/ToastMsg";
import { ViewFiles } from "./ViewFiles";
import FileUploadDocuments from "./FileUploadDocuments";
import { Button, Container, Spinner } from "react-bootstrap";

interface DocumentsState {
  myCase: boolean;
  getData: boolean;
  setUserToSave: any;
}

const Documents = ({ myCase, getData, setUserToSave }: DocumentsState) => {
  const { id } = useParams();
  const [docs, setDocs] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getDocuments();
    }
  }, [getData]);

  const getDocuments = async () => {
    await fetchDocuments(id)
      .then((res: any) => {
        const documents = res[0].data;
        setDocs(documents);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <h4 className="mb-0 app-header-text">Documents</h4>
          <ViewFiles docs={docs} fetchDocuments={getDocuments} myCase={myCase} setIsLoading={setIsLoading} />
          {myCase && (
            <FileUploadDocuments
              header="Upload Document"
              fetchDocuments={getDocuments}
              setShowToast={setShowToast}
              setToastText={setToastText}
              setToastBody={setToastBody}
              setUserToSave={setUserToSave}
            />
          )}
        </>
      )}
    </>
  );
};

export default Documents;
