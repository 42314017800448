import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Stack, Button, Spinner, Container } from "react-bootstrap";
import {
  fetchEthnicities,
  fetchGenders,
  fetchRaces,
  fetchRelationships,
  patchCaseData,
  postUserData,
} from "../../services/httpClient";
import DatePicker from "../ui/DatePicker";
import Input from "../ui/Input";
import SelectObject from "../ui/SelectObject";
import InputPhone from "../ui/InputPhone";
import { useParams } from "react-router-dom";
import ToastMsg from "../ui/ToastMsg";

interface CreateGuardState {
  setShowCreateFamily: any;
  family: any[];
  setFamily: any;
}

const CreateFamilyGuardian = ({ setShowCreateFamily, family, setFamily }: CreateGuardState) => {
  const { id } = useParams();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [preferredName, setPreferredName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [relationship, setRelationship] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>();
  const [gender, setGender] = useState<string>("");
  const [race, setRace] = useState<string>("");
  const [ethnicity, setEthnicity] = useState<string>("");

  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [relationships, setRelationships] = useState<any[]>([]);
  const [genders, setGenders] = useState<any[]>([]);
  const [races, setRaces] = useState<any[]>([]);
  const [ethnicities, setEthnicities] = useState<any[]>([]);

  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  const mainRef = useRef();

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    getRelationships();
    getGenders();
    getRaces();
    getEthnicities();
    handleScroll(mainRef);
  }, []);

  const getRelationships = () => {
    fetchRelationships().then((res: any) => {
      setRelationships(res[0].data);
    });
  };

  const getGenders = () => {
    fetchGenders().then((res: any) => {
      setGenders(res[0].data);
    });
  };

  const getRaces = () => {
    fetchRaces().then((res: any) => {
      setRaces(res[0].data);
    });
  };

  const getEthnicities = () => {
    fetchEthnicities().then((res: any) => {
      setEthnicities(res[0].data);
    });
  };

  function validateEmail(email: string) {
    var regexp = /\S+@\S+\.\S+/;
    return regexp.test(email);
  }

  function validatePhone(phone: string) {
    let length = phone.length;
    return length === 11;
  }

  const updateFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const updateLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const updatePreferredName = (e: any) => {
    setPreferredName(e.target.value);
  };

  const updateEmail = (e: any) => {
    setEmail(e.target.value);

    let validEmail = validateEmail(e.target.value);
    if (validEmail) {
      setIsValidEmail(true);
      if (isValidPhone) {
        if (phone) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      }
    } else {
      setIsValidEmail(false);
      setSaveIsEnabled(false);
    }
  };

  const updatePhone = (e: any) => {
    setPhone(e);
    let validPhone = validatePhone(e);
    if (validPhone) {
      setIsValidPhone(true);
      if (isValidEmail) {
        if (email) {
          setSaveIsEnabled(true);
        } else {
          setSaveIsEnabled(false);
        }
      }
    } else {
      setIsValidPhone(false);
      setSaveIsEnabled(false);
    }
  };

  const updateRelationship = (e: any) => {
    setRelationship(e.target.value);
  };

  const updateGender = (e: any) => {
    setGender(e.target.value);
  };

  const updateRace = (e: any) => {
    setRace(e.target.value);
  };

  const updateEthnicity = (e: any) => {
    setEthnicity(e.target.value);
  };

  const onClickSave = () => {
    setErrMsg("");

    if (isValidEmail && isValidPhone) {
      setIsLoading(true);
      setShowToast(true);
      setToastText("Case Updated");
      setToastBody(firstName + " Added");

      var updatedUser: any = {
        role: "family",
        email: email,
        firstname: firstName,
        lastname: lastName,
        preferredname: preferredName,
        phonenumber: phone,
        relationship: relationship,
        birthdate: birthDate,
        gender: gender,
        race: race,
        ethnicity: race,
        is_guardian: true,
      };

      postUserData(updatedUser)
        .then((res: any) => {
          setSaveIsEnabled(false);
          clearUserState();

          updatedUser["id"] = res[0].data.user_id;
          const updatedFamily = [...family, updatedUser];
          setFamily(updatedFamily);

          const updatedCase = {
            family: updatedFamily.map((i: any) => {
              return i.id;
            }),
          };

          patchCaseData(updatedCase, id)
            .then((res: any) => {
              clearUserState();
              setShowCreateFamily(false);
            })
            .catch((err: any) => {
              console.log(err);
            });
        })
        .catch((err: any) => {
          setIsLoading(false);
          setErrMsg(err?.response?.data?.msg);
        })
        .finally(() => {
          setIsLoading(false);
          handleScroll(mainRef);
        });
    } else {
      setErrMsg("Email or Phone is in Invalid Format");
    }
  };

  const clearUserState = () => {
    setFirstName("");
    setLastName("");
    setPreferredName("");
    setEmail("");
    setPhone(null);
    setRelationship("");
    setBirthDate("");
    setGender("");
    setRace("");
    setEthnicity("");
  };

  const onClickCancel = () => {
    setShowCreateFamily(false);
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Card className="bg-light p-3 mb-5">
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <Row className="mb-3">
            <Col>
              <h4 className="app-header-text">Create Family Guardian</h4>
            </Col>
            <Col className="text-end">
              <Stack className="stack-float" direction="horizontal" gap={3}>
                <Button className="app-secondary-color button" onClick={() => onClickSave()} disabled={!saveIsEnabled}>
                  Save
                </Button>
                <div className="vr" />
                <Button className="app-primary-color button" onClick={() => onClickCancel()}>
                  Done
                </Button>
              </Stack>
            </Col>
          </Row>
          <Stack gap={3}>
            <Row>
              <Col xs={6} lg={4}>
                <Input label="First Name" type="text" value={firstName} onUpdate={(e: any) => updateFirstName(e)} />
              </Col>
              <Col xs={6} lg={4}>
                <Input label="Last Name" type="text" value={lastName} onUpdate={(e: any) => updateLastName(e)} />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Input label="Email" type="text" value={email} onUpdate={(e: any) => updateEmail(e)} required={true} />
              </Col>
              <Col xs={6} lg={4}>
                <InputPhone label="Phone" value={phone} onUpdate={(e: any) => updatePhone(e)} required={true} />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Input
                  label="Preferred Name"
                  type="text"
                  value={preferredName}
                  onUpdate={(e: any) => updatePreferredName(e)}
                />
              </Col>
              <Col xs={6} lg={4}>
                <DatePicker label="Birth Date" currentValue={birthDate} setCurrentValue={setBirthDate} />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <SelectObject
                  label="Relationship"
                  value={relationship}
                  options={relationships}
                  onUpdate={(e: any) => updateRelationship(e)}
                />
              </Col>
              <Col xs={6} lg={4}>
                <SelectObject label="Gender" value={gender} options={genders} onUpdate={(e: any) => updateGender(e)} />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <SelectObject
                  label="Ethnicity"
                  value={ethnicity}
                  options={ethnicities}
                  onUpdate={(e: any) => updateEthnicity(e)}
                />
              </Col>
              <Col xs={6} lg={4}>
                <SelectObject label="Race" value={race} options={races} onUpdate={(e: any) => updateRace(e)} />
              </Col>
            </Row>
          </Stack>
        </Card>
      )}
      <Row ref={mainRef}></Row>
    </>
  );
};

export default CreateFamilyGuardian;
