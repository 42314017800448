import React, { useEffect, useRef, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import DatePicker from "../../../ui/DatePicker";
import FormCheck from "../../../ui/FormCheckbox";
import SelectObject from "../../../ui/SelectObject";
import Textarea from "../../../ui/Textarea";
import SelectFamily from "./SelectFamily";
import ViewRecordings from "./ViewRecordings";
import { User } from "../../../../types/user";

interface ReferralState {
  note?: any;
  family: User[];
  setUpdatedNote: any;
  setSaveIsEnabled: any;
  setUserToSave: any;
  myCase?: boolean;
  editNote?: boolean;
}

const Referral = ({
  note,
  family,
  setUpdatedNote,
  setSaveIsEnabled,
  setUserToSave,
  myCase,
  editNote = false,
}: ReferralState) => {
  const mainRef = useRef();
  const [familyUser, setFamilyUser] = useState<string>("");
  const [incomeRef, setIncomeRef] = useState<boolean>(false);
  const [educationRef, setEducationRef] = useState<boolean>(false);
  const [employmentRef, setEmploymentRef] = useState<boolean>(false);
  const [childcareRef, setChildcareRef] = useState<boolean>(false);
  const [healthRef, setHealthRef] = useState<boolean>(false);
  const [nutritionRef, setNutritionRef] = useState<boolean>(false);
  const [housingRef, setHousingRef] = useState<boolean>(false);
  const [transRef, setTransRef] = useState<boolean>(false);
  const [supportRef, setSupportRef] = useState<boolean>(false);
  const [familyRef, setFamilyRef] = useState<boolean>(false);
  const [referredTo, setReferredTo] = useState<string>("");
  const [refContact, setRefContact] = useState<string>("");
  const [refReason, setRefReason] = useState<string>("");
  const [noteDate, setNoteDate] = useState<string>("");
  const [outcome, setOutcome] = useState<string>("");
  const [action, setAction] = useState<string>("");

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  var actions: any[] = [
    { id: 0, key: "yes", name: "Yes" },
    { id: 1, key: "no", name: "No" },
  ];

  useEffect(() => {
    handleScroll(mainRef);

    if (editNote) {
      setFamilyUser(note?.subject?.id + "|" + note?.subject?.type);
      setIncomeRef(convertToBoolean(note.fields.type_income));
      setEducationRef(convertToBoolean(note.fields.type_education));
      setEmploymentRef(convertToBoolean(note.fields.type_employment));
      setChildcareRef(convertToBoolean(note.fields.type_childcare));
      setHealthRef(convertToBoolean(note.fields.type_healthcare));
      setNutritionRef(convertToBoolean(note.fields.type_nutrition));
      setHousingRef(convertToBoolean(note.fields.type_housing));
      setTransRef(convertToBoolean(note.fields.type_transportation));
      setSupportRef(convertToBoolean(note.fields.type_support));
      setFamilyRef(convertToBoolean(note.fields.type_family));
      setReferredTo(note.fields.referred_to);
      setRefContact(note.fields.referral_contact);
      setRefReason(note.fields.reason);
      setNoteDate(note.fields.date);
      setOutcome(note.fields.outcome);
      setAction(note.fields.represent);
    }
  }, []);

  const convertToBoolean = (field: any) => {
    return field === "0" ? false : true;
  };

  useEffect(() => {
    const parts = familyUser.split("|");

    const note = {
      notetype: "referral",
      subject: {
        id: parts[0],
        type: parts[1],
      },
      fields: {
        date: noteDate,
        type_income: incomeRef,
        type_education: educationRef,
        type_employment: employmentRef,
        type_childcare: childcareRef,
        type_healthcare: healthRef,
        type_nutrition: nutritionRef,
        type_housing: housingRef,
        type_transportation: transRef,
        type_support: supportRef,
        type_family: familyRef,
        referred_to: referredTo,
        referral_contact: refContact,
        reason: refReason,
        outcome: outcome,
        represent: action,
      },
    };

    setUpdatedNote(note);
  }, [
    noteDate,
    incomeRef,
    educationRef,
    employmentRef,
    childcareRef,
    healthRef,
    nutritionRef,
    housingRef,
    transRef,
    supportRef,
    familyRef,
    referredTo,
    refContact,
    refReason,
    outcome,
    action,
    familyUser,
    setUpdatedNote,
  ]);

  const updateFamilyUser = (e: any) => {
    setFamilyUser(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateIncomeRef = () => {
    setIncomeRef(!incomeRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateEducationRef = () => {
    setEducationRef(!educationRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateEmploymentRef = () => {
    setEmploymentRef(!employmentRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateChildcareRef = () => {
    setChildcareRef(!childcareRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateHealthRef = () => {
    setHealthRef(!healthRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateNutritionRef = () => {
    setNutritionRef(!nutritionRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateHousingRef = () => {
    setHousingRef(!housingRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateTransRef = () => {
    setTransRef(!transRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateSupportRef = () => {
    setSupportRef(!supportRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateFamilyRef = () => {
    setFamilyRef(!familyRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateReferredTo = (e: any) => {
    setReferredTo(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateRefContact = (e: any) => {
    setRefContact(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateRefReason = (e: any) => {
    setRefReason(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateOutcome = (e: any) => {
    setOutcome(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateActions = (e: any) => {
    setAction(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateDate = () => {
    setSaveIsEnabled(true);
    setUserToSave(true);
  };

  useEffect(() => {
    if (!noteDate) {
      setSaveIsEnabled(false);
      setUserToSave(false);
    }
  }, [noteDate]);

  return (
    <>
      <Row className="mt-4" ref={mainRef}>
        <SelectFamily
          family={family}
          familyUser={familyUser}
          updateFamilyUser={updateFamilyUser}
          readOnly={myCase ? false : true}
        />
        <Col className="col-lg-4 col-md-6 col-sm-8">
          <DatePicker
            label="Date"
            currentValue={noteDate}
            setCurrentValue={setNoteDate}
            onUpdate={() => updateDate()}
            required={true}
            hasMaxDate={true}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      {note?.recordingsExist === true && <ViewRecordings note={note} editNote={editNote} myCase={myCase} />}
      <Row className="mt-3">
        <h6>Referral Type</h6>
        <Col className="col-6">
          <FormCheck
            label="Income"
            value={incomeRef}
            onUpdate={(e: any) => updateIncomeRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Education"
            value={educationRef}
            onUpdate={(e: any) => updateEducationRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Employment"
            value={employmentRef}
            onUpdate={(e: any) => updateEmploymentRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Childcare"
            value={childcareRef}
            onUpdate={(e: any) => updateChildcareRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Healthcare & Health Status"
            value={healthRef}
            onUpdate={(e: any) => updateHealthRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Nutrition"
            value={nutritionRef}
            onUpdate={(e: any) => updateNutritionRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Housing"
            value={housingRef}
            onUpdate={(e: any) => updateHousingRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Transportation"
            value={transRef}
            onUpdate={(e: any) => updateTransRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Support Systems"
            value={supportRef}
            onUpdate={(e: any) => updateSupportRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Family Interactions"
            value={familyRef}
            onUpdate={(e: any) => updateFamilyRef()}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-6">
          <FloatingLabel controlId="floatingInput" label="Referred To">
            <Form.Control
              type="text"
              value={referredTo}
              onChange={(e) => updateReferredTo(e)}
              disabled={myCase ? false : true}
            />
          </FloatingLabel>
        </Col>
        <Col className="col-6">
          <FloatingLabel controlId="floatingInput" label="Referral Contact Information">
            <Form.Control
              type="text"
              value={refContact}
              onChange={(e) => updateRefContact(e)}
              disabled={myCase ? false : true}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Textarea
          label="Reason for Referral"
          value={refReason}
          onUpdate={(e: any) => updateRefReason(e)}
          disabled={myCase ? false : true}
        />
      </Row>
      <Row>
        <Textarea
          label="Anticipated / Desired Outcome"
          value={outcome}
          onUpdate={(e: any) => updateOutcome(e)}
          disabled={myCase ? false : true}
        />
      </Row>
      <Row className="mt-3 mb-5">
        <Col className="col-12">
          <SelectObject
            label="Does the above accurately represent the actions taken on behalf of the client?"
            value={action}
            options={actions}
            onUpdate={(e: any) => updateActions(e)}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
    </>
  );
};

export default Referral;
