import React, { useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { banGuardian, banMember, deleteGroupParticipant, postNote, postSummary } from "../../../../services/httpClient";
import Input from "../../../ui/Input";

interface BanState {
  user: any;
  isBanned: boolean;
  setIsBanning: any;
  userToBan: string | undefined;
  setUserToBan: any;
  fetchHousehold: any;
  resetHouseholdTab: any;
  type: string;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const ConfirmBan = ({
  user,
  isBanned,
  setIsBanning,
  userToBan,
  setUserToBan,
  fetchHousehold,
  resetHouseholdTab,
  type,
  setShowToast,
  setToastText,
  setToastBody,
}: BanState) => {
  const { id } = useParams();
  const [userId, setUserId] = useState(user?.id);
  const [needNote, setNeedNote] = useState<boolean>(false);
  const [banReason, setBanReason] = useState<string>("");
  const [allowBan, setAllowBan] = useState<boolean>(false);

  const onClickBanContinue = () => {
    setNeedNote(true);
  };

  const updateBanReason = (e: any) => {
    setBanReason(e.target.value);
    setAllowBan(true);
  };

  const onClickBanConfirm = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const currentDateString = `${year}-${month}-${day}`;

    if (type === "Guardian") {
      const banObj = {
        is_banned: !isBanned,
      };

      banGuardian(banObj, id, userId)
        .then((res: any) => {
          const note = {
            notetype: "infraction",
            subject: {
              id: userId,
              type: "guardian",
            },
            fields: {
              reason: banReason,
              date: currentDateString,
            },
          };

          postNote(note, id).then((res: any) => {
            postSummary(id)
              .then((res: any) => {
                var magic = res[0].data.summary.postResponse;
              })
              .catch((err: any) => {
                console.log(err);
              });
          });

          user?.groups?.map((g: any) => {
            deleteGroupParticipant(g?.id, g?.group_participant_id).then((res: any) => {});
          });

          resetHouseholdTab();
          fetchHousehold();
          setShowToast(true);
          setToastText(isBanned ? "Guardian Unbanned" : "Guardian Banned");
          setToastBody(userToBan);
          setIsBanning(false);
          setUserToBan("");
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (type === "Member") {
      const banObj = {
        is_banned: !isBanned,
      };

      banMember(banObj, id, userId)
        .then((res: any) => {
          const note = {
            notetype: "infraction",
            subject: {
              id: userId,
              type: "member",
            },
            fields: {
              reason: banReason,
              date: currentDateString,
            },
          };

          postNote(note, id).then((res: any) => {
            postSummary(id)
              .then((res: any) => {
                var magic = res[0].data.summary.postResponse;
              })
              .catch((err: any) => {
                console.log(err);
              });
          });

          user?.groups?.map((g: any) => {
            deleteGroupParticipant(g?.id, g?.group_participant_id).then((res: any) => {});
          });

          resetHouseholdTab();
          fetchHousehold();
          setShowToast(true);
          setToastText(isBanned ? "Member Unbanned" : "Member Banned");
          setToastBody(userToBan);
          setIsBanning(false);
          setUserToBan("");
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const onClickCancelBan = () => {
    setIsBanning(false);
    setNeedNote(false);
    setUserToBan("");
    setAllowBan(false);
  };

  return needNote ? (
    <Card className="bg-light mb-3">
      <Card.Body>
        <Stack direction="horizontal" gap={3}>
          <Stack style={{ justifyContent: "center" }}>
            <Input
              label={isBanned ? "Enter Unban Reason" : "Enter Ban Reason"}
              type="text"
              value={banReason}
              hasFocus={true}
              onUpdate={(e: any) => updateBanReason(e)}
            />{" "}
          </Stack>
          <Button className="app-danger-color button" onClick={() => onClickBanConfirm()} disabled={!allowBan}>
            Confirm {isBanned ? "Unban" : "Ban"}
          </Button>
          <div className="vr" />
          <Button className="app-primary-color button" onClick={() => onClickCancelBan()}>
            Cancel
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  ) : (
    <Card className="bg-light mb-3">
      <Card.Body>
        <Stack direction="horizontal" gap={3}>
          <Stack style={{ justifyContent: "center" }}>
            <Card.Subtitle>
              Are you sure you want to {isBanned ? "unban" : "ban"} {userToBan}?
            </Card.Subtitle>
          </Stack>
          <Button className="app-danger-color button" onClick={() => onClickBanContinue()}>
            Continue
          </Button>
          <div className="vr" />
          <Button className="app-primary-color button" onClick={() => onClickCancelBan()}>
            Cancel
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default ConfirmBan;
