import React, { useEffect, useState } from "react";
import { Row, Col, Container, Tab, Nav, Button, Spinner } from "react-bootstrap";
import { fetchAnnouncements } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import ToastMsg from "../ui/ToastMsg";
import { Announcement } from "../../types/announcement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faClockFour, faEdit } from "@fortawesome/free-solid-svg-icons";
import AnnouncementTab from "./AnnouncementTab";
import useApp from "../../utils/useApp";
import { trackNavigationEvent } from "../../utils/analytics";
import useMenuToggle from "../../utils/useMenuToggle";

const Announcements = () => {
  const { showMenu }: any = useApp();
  const toggleMenu = useMenuToggle();
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [published, setPublished] = useState<Announcement[]>([]);
  const [drafts, setDrafts] = useState<Announcement[]>([]);
  const [expired, setExpired] = useState<Announcement[]>([]);
  // const [deleted, setDeleted] = useState<Announcement[]>([]);
  const [activePublished, setActivePublished] = useState<boolean>(false);
  const [activeDrafts, setActiveDrafts] = useState<boolean>(false);
  const [activeExpired, setActiveExpired] = useState<boolean>(false);
  // const [activeDeleted, setActiveDeleted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  useEffect(() => {
    setActivePublished(true);
    setActiveDrafts(false);
    setActiveExpired(false);
    // setActiveDeleted(false);
    getAnnouncements();

    trackNavigationEvent("switch_view", "published_announcements");
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    setPublished(
      announcements.filter((x: any) => {
        const compareDate = new Date(`${x.event_at}T00:00:00`);
        return compareDate >= currentDateOnly && x.is_published === 1 && x.deleted_at === null;
      })
    );
    setDrafts(
      announcements.filter((x: any) => {
        return x.is_published !== 1 && x.deleted_at === null;
      })
    );
    setExpired(
      announcements.filter((x: any) => {
        const compareDate = new Date(`${x.event_at}T00:00:00`);
        return compareDate < currentDateOnly && x.is_published === 1 && x.deleted_at === null;
      })
    );
    // setDeleted(announcements.filter((x: any) => x.deleted_at !== null));
  }, [announcements]);

  const getAnnouncements = () => {
    setIsLoading(true);

    fetchAnnouncements()
      .then((res: any) => {
        const a = res[0].data;
        setAnnouncements(a);
        setActivePublished(true);
        setActiveDrafts(false);
        setActiveExpired(false);
        // setActiveDeleted(false);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onNavClick = (route: string) => {
    if (route) {
      trackNavigationEvent("switch_view", route + "_announcements");
    }

    switch (route) {
      case "published":
        setActivePublished(true);
        setActiveDrafts(false);
        setActiveExpired(false);
        // setActiveDeleted(false);
        break;
      case "drafts":
        setActivePublished(false);
        setActiveDrafts(true);
        setActiveExpired(false);
        // setActiveDeleted(false);
        break;
      case "expired":
        setActivePublished(false);
        setActiveDrafts(false);
        setActiveExpired(true);
        // setActiveDeleted(false);
        break;
      // case "deleted":
      //   setActivePublished(false);
      //   setActiveDrafts(false);
      //   setActiveExpired(false);
      //   setActiveDeleted(true);
      //   break;
      default:
        setActivePublished(true);
        setActiveDrafts(false);
        setActiveExpired(false);
        // setActiveDeleted(false);
        break;
    }
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Tab.Container defaultActiveKey="published">
            <Row>
              <Col sm={3}>
                <Nav
                  variant="pills"
                  className={
                    showMenu
                      ? "flex-column sticky-top mycase-menu-open-sticky"
                      : "flex-column sticky-top mycase-menu-sticky"
                  }
                >
                  <Nav.Item className="mb-2">
                    <PageTitle title={"Announcements"} />
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="published"
                      className={activePublished ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("published")}
                    >
                      <>
                        <FontAwesomeIcon icon={faBullhorn} className={"me-2"} />
                        {toggleMenu ? "Published" : "Publish"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="drafts"
                      className={activeDrafts ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("drafts")}
                    >
                      <>
                        <FontAwesomeIcon icon={faEdit} className={"me-2"} />
                        {"Drafts"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="expired"
                      className={activeExpired ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("expired")}
                    >
                      <>
                        <FontAwesomeIcon icon={faClockFour} className={"me-2"} />
                        {"Expired"}
                      </>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="d-grid">
                    <Nav.Link
                      eventKey="deleted"
                      className={activeDeleted ? "nav-vertical-active" : "nav-vertical"}
                      onClick={() => onNavClick("deleted")}
                    >
                      <FontAwesomeIcon icon={faTrash} className={"me-2"} />
                      {"Deleted"}
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="published">
                    <AnnouncementTab
                      type="published"
                      announcements={published}
                      getAnnouncements={getAnnouncements}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                      setIsLoading={setIsLoading}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="drafts">
                    <AnnouncementTab
                      type="drafts"
                      announcements={drafts}
                      getAnnouncements={getAnnouncements}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                      setIsLoading={setIsLoading}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="expired">
                    <AnnouncementTab
                      type="expired"
                      announcements={expired}
                      getAnnouncements={getAnnouncements}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                      setIsLoading={setIsLoading}
                    />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="deleted">
                    <AnnouncementTab
                      type="deleted"
                      announcements={deleted}
                      getAnnouncements={getAnnouncements}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                      setIsLoading={setIsLoading}
                    />
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      )}
    </>
  );
};

export default Announcements;
