import React from "react";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { User } from "../../types/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

interface GuardianTabState {
  users: User[];
}

const GuardianTab = ({ users }: GuardianTabState) => {
  function formatPhoneNumber(phoneNumber: string) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  }

  return (
    <>
      <Row className="mb-5">
        <Col xs={12}>
          <Stack gap={3}>
            {users.map((user: any) => (
              <Card key={user.id} className={user?.is_banned ? "bg-light card-danger" : "bg-light"}>
                <Card.Body>
                  <Stack direction="horizontal" gap={3}>
                    <Stack>
                      <Card.Subtitle>
                        {user.firstname} {user.preferredname ? "'" + user.preferredname + "'" : ""} {user.lastname}
                      </Card.Subtitle>
                      <Card.Subtitle className="text-muted mt-1">
                        {user.email +
                          (user.email && user.phonenumber ? " | " : "") +
                          formatPhoneNumber(user.phonenumber)}{" "}
                      </Card.Subtitle>
                    </Stack>
                    <Card.Subtitle className="text-muted mt-1">{user.casename} </Card.Subtitle>
                  </Stack>
                  <Row>
                    <Col className="col-12 text-end">
                      {user?.is_banned === 1 && (
                        <div className="position-absolute top-0 end-0">
                          <Stack direction="horizontal">
                            <small className="small-text app-danger-text me-1 mt-2">Banned</small>
                            <FontAwesomeIcon icon={faBan} className="app-danger-text me-2 mt-2" />
                          </Stack>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </Stack>
        </Col>
      </Row>
    </>
  );
};

export default GuardianTab;
