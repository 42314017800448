import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Col, Form, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import Input from "./Input";
import { Task } from "../../types/task";

const CreateGoalCard = ({
  onClickSaveGoal,
  goalSaved,
  setGoalSaved,
  tasks,
  setTasks,
  onClickRemoveTask,
  onClickSaveTask,
}: any) => {
  const [goalName, setGoalName] = useState<string>("");
  const [task, setTask] = useState<string>("");
  const [allowSaveGoal, setAllowSaveGoal] = useState<boolean>(false);

  const updateGoalName = (e: any) => {
    setGoalName(e.target.value);
    setAllowSaveGoal(true);
  };

  const updateTask = (e: any) => {
    setTask(e.target.value);
  };

  const onClickGoalSubmit = (e: any) => {
    e?.preventDefault();
    onClickSaveGoal(goalName);
  };

  const onClickTaskSubmit = (e: any) => {
    e?.preventDefault();
    setTask("");
    onClickSaveTask(task);
  };

  const onClickStartOver = () => {
    setGoalName("");
    setTask("");
    setTasks([]);
    setGoalSaved(false);
  };

  return (
    <>
      <Row>
        <Col className="me-0 pe-0">
          <Card className="bg-light">
            <Card.Body>
              <Row>
                <h6 className="ps-3 ms-3">Goal</h6>
              </Row>
              <Row className="mb-3">
                <Col xs={12}>
                  <Form onSubmit={(e: any) => onClickGoalSubmit(e)}>
                    <Stack direction="horizontal" gap={3}>
                      <Stack style={{ justifyContent: "center" }} className="col-6">
                        <Input
                          label="Enter New Goal"
                          type="textarea"
                          value={goalName}
                          onUpdate={(e: any) => updateGoalName(e)}
                          disabled={goalSaved ? true : false}
                        />
                      </Stack>
                      {!goalSaved && (
                        <Button
                          className="button app-secondary-color button"
                          onClick={() => onClickSaveGoal(goalName)}
                          disabled={!allowSaveGoal}
                        >
                          Save
                        </Button>
                      )}
                    </Stack>
                  </Form>
                </Col>
              </Row>
              {goalSaved && (
                <>
                  <Row>
                    <h6 className="ps-3 ms-3">Tasks</h6>
                  </Row>
                  {tasks.map((t: Task) => (
                    <Row className="mb-2">
                      <Col className="col-11">
                        <Form.Control type="text" value={t.name} readOnly />
                      </Col>
                      <Col className="align-self-center p-0">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Delete Task</Tooltip>}
                        >
                          <FontAwesomeIcon
                            className="text-muted cursor-pointer"
                            icon={faTrash}
                            onClick={(e) => onClickRemoveTask(t)}
                          />
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  ))}
                  <Form onSubmit={(e: any) => onClickTaskSubmit(e)}>
                    <Row>
                      <Col xs={12}>
                        <Stack direction="horizontal" gap={3}>
                          <Stack style={{ justifyContent: "center" }} className="col-6">
                            <Input
                              label="Enter New Task"
                              type="text"
                              value={task}
                              onUpdate={(e: any) => updateTask(e)}
                            />
                          </Stack>
                          <Button
                            className="button app-secondary-color"
                            onClick={() => onClickSaveTask(task)}
                            disabled={!allowSaveGoal}
                          >
                            Save
                          </Button>
                        </Stack>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        {goalSaved && (
          <Row className="mt-3">
            <Col className="text-center">
              <Button className="button app-primary-color" onClick={() => onClickStartOver()}>
                Create Another Goal
              </Button>
            </Col>
          </Row>
        )}
      </Row>
    </>
  );
};

export default CreateGoalCard;
