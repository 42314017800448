import React, { useState } from "react";
import { postSupportsRelease } from "../../../../services/httpClient";
import { useParams } from "react-router-dom";
import FileUploadUI from "../../../ui/FileUploadUI";
import { Button, Container, Spinner } from "react-bootstrap";

interface FileUploadSupportsState {
  header: string;
  setIfFileExists: any;
  setRelease: any;
  userId: string | undefined;
}

const FileUploadSupports = ({ header, setIfFileExists, setRelease, userId }: FileUploadSupportsState) => {
  const { id } = useParams();

  const [selectedFile, setSelectedFile]: any = useState();
  const [uploadIsEnabled, setUploadIsEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  async function uploadFileData(data: any) {
    setIsLoading(true);

    postSupportsRelease(selectedFile.file.name, data, id, userId)
      .then((res: any) => {
        setRelease({
          id: res[0]?.data?.support_release_id,
          name: selectedFile.file.name,
        });
        setIfFileExists(true);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <FileUploadUI
          header={header}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          uploadIsEnabled={uploadIsEnabled}
          setUploadIsEnabled={setUploadIsEnabled}
          uploadFileData={uploadFileData}
        />
      )}
    </>
  );
};

export default FileUploadSupports;
