import { faAdd, faAngleDown, faAngleRight, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Col, ListGroup, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import { Goal } from "../../../types/goal";
import useApp from "../../../utils/useApp";
import { deleteGoalTemplate, patchGoalTemplate, postTaskTemplate } from "../../../services/httpClient";
import { unsavedChanges } from "../../../App";
import Input from "../../ui/Input";
import { Task } from "../../../types/task";
import TaskTemplateItem from "./TaskTemplateItem";

interface GoalTemplateState {
  goal: Goal;
  getGoalTemplates: any;
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const GoalTemplateCard = ({
  goal,
  getGoalTemplates,
  setOrgToSave,
  setShowToast,
  setToastText,
  setToastBody,
}: GoalTemplateState) => {
  const { auth }: any = useApp();
  const [isEditing, setIsEditing] = useState(false);
  const [isTaskEditing, setIsTaskEditing] = useState(false);
  const [allowSaveGoal, setAllowSaveGoal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState<Goal>();
  const [goalName, setGoalName] = useState<string>(goal?.name);
  const [showAddTask, setShowAddTask] = useState<boolean>(false);
  const [newTask, setNewTask] = useState<string>("");
  const [showTasks, setShowTasks] = useState<boolean>(false);
  const [allowSaveTask, setAllowSaveTask] = useState<boolean>(false);

  const onClickEdit = () => {
    setIsEditing(true);
  };

  const onClickDelete = () => {
    console.log(goal);
    setIsDeleting(true);
    setGoalToDelete(goal);
  };

  const onClickDeleteFinal = () => {
    deleteGoalTemplate(auth?.profile?.userOrgID, goalToDelete?.id).then((res: any) => {
      setIsDeleting(false);
      setGoalToDelete(undefined);
      getGoalTemplates();
      setShowToast(true);
      setToastText("Goal Template Deleted");
      setToastBody(goalToDelete?.name);
    });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setIsEditing(false);
    setIsTaskEditing(false);
    setGoalToDelete(undefined);
  };

  const onClickSave = () => {
    const updatedGoal = {
      name: goalName,
    };

    patchGoalTemplate(updatedGoal, auth?.profile?.userOrgID, goal?.id).then((res: any) => {
      setIsEditing(false);
      setAllowSaveGoal(false);
      unsavedChanges.value = false;
      setOrgToSave(false);
      getGoalTemplates();
      setShowToast(true);
      setToastText("Goal Template Upldated");
      setToastBody(goalName);
    });
  };

  const onClickCancel = () => {
    setIsEditing(false);
    unsavedChanges.value = false;
    setOrgToSave(false);
  };

  const updateGoalName = (e: any) => {
    setGoalName(e.target.value);
    setAllowSaveGoal(true);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickAddTask = () => {
    setShowAddTask(true);
    setShowTasks(true);
  };

  const updateTask = (e: any) => {
    setNewTask(e.target.value);
    setAllowSaveTask(true);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickSaveTask = () => {
    const task = {
      name: newTask,
    };

    postTaskTemplate(task, auth?.profile?.userOrgID, goal?.id).then((res: any) => {
      setShowAddTask(false);
      setNewTask("");
      setAllowSaveTask(false);
      unsavedChanges.value = false;
      setOrgToSave(false);
      getGoalTemplates();
      setShowToast(true);
      setToastText("Task Created");
      setToastBody(newTask);
    });
  };

  const onClickCancelTask = () => {
    setShowAddTask(false);
    setShowTasks(false);
    setNewTask("");
    setAllowSaveTask(false);
    unsavedChanges.value = false;
    setOrgToSave(false);
  };

  const renderDelete = () => {
    return (
      <Card className="bg-light mb-3">
        <Card.Body>
          <Stack direction="horizontal" gap={3}>
            <Stack style={{ justifyContent: "center" }} className="col-6">
              <Card.Subtitle>Are you sure you want to delete - {goalToDelete?.name}?</Card.Subtitle>
            </Stack>
            <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
              Confirm Delete
            </Button>
            <div className="vr" />
            <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
              Cancel
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {isDeleting ? (
        <>{renderDelete()}</>
      ) : (
        <Card className="bg-light mb-3">
          <Card.Body>
            <Row>
              <Col xs={12}>
                {isEditing ? (
                  <Stack direction="horizontal" gap={3} className="mb-3">
                    <Stack style={{ justifyContent: "center" }} className="col-6">
                      <Input label="Goal" type="textarea" value={goalName} onUpdate={(e: any) => updateGoalName(e)} />
                    </Stack>
                    <Button
                      className="button app-secondary-color button"
                      onClick={() => onClickSave()}
                      disabled={!allowSaveGoal}
                    >
                      Save
                    </Button>
                    <div className="vr" />
                    <Button className="button app-primary-color button" onClick={() => onClickCancel()}>
                      Cancel
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="horizontal" gap={3}>
                    <Stack style={{ justifyContent: "center" }} className="col-6">
                      <h5 className="app-orange-text ms-3">{goal.name}</h5>
                    </Stack>
                    {isEditing || isTaskEditing || showAddTask ? (
                      <FontAwesomeIcon className={"app-light-gray-text"} icon={faEdit} />
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Edit Goal</Tooltip>}
                      >
                        <FontAwesomeIcon
                          className="text-muted cursor-pointer"
                          icon={faEdit}
                          onClick={(e) => onClickEdit()}
                        />
                      </OverlayTrigger>
                    )}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>Delete Goal</Tooltip>}
                    >
                      <FontAwesomeIcon
                        className="text-muted cursor-pointer me-3 pe-2"
                        icon={faTrash}
                        onClick={(e) => onClickDelete()}
                      />
                    </OverlayTrigger>
                  </Stack>
                )}
              </Col>
            </Row>
            <Row>
              {goal?.tasks?.length > 0 ? (
                <h6 className="ms-3 cursor-pointer" onClick={() => setShowTasks(!showTasks)}>
                  Tasks <FontAwesomeIcon icon={!showTasks ? faAngleRight : faAngleDown} className="ms-2" />
                </h6>
              ) : (
                <h6 className="ms-3">Tasks</h6>
              )}
            </Row>
            {showTasks && (
              <Row className="mb-2">
                <ListGroup className="pe-0">
                  {goal?.tasks.map((t: Task) => (
                    <TaskTemplateItem
                      t={t}
                      goalId={goal?.id}
                      getGoalTemplates={getGoalTemplates}
                      isGoalEditing={isEditing}
                      isTaskEditing={isTaskEditing}
                      setIsTaskEditing={setIsTaskEditing}
                      showAddTask={showAddTask}
                      setOrgToSave={setOrgToSave}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                    />
                  ))}
                </ListGroup>
              </Row>
            )}
            <Row className="mb-0">
              {showAddTask ? (
                <Col xs={12}>
                  <Stack direction="horizontal" gap={3}>
                    <Stack style={{ justifyContent: "center" }} className="col-6">
                      <Input
                        label="Enter New Task"
                        type="text"
                        value={newTask}
                        hasFocus={true}
                        onUpdate={(e: any) => updateTask(e)}
                      />
                    </Stack>
                    <Button
                      type="submit"
                      className="app-secondary-color button"
                      disabled={!allowSaveTask}
                      onClick={() => onClickSaveTask()}
                    >
                      Save
                    </Button>
                    <div className="vr" />
                    <Button className="app-primary-color button" onClick={() => onClickCancelTask()}>
                      Cancel
                    </Button>
                  </Stack>
                </Col>
              ) : (
                <Col xs={12} className="text-end">
                  <Button className="app-tertiary-color button me-3" onClick={() => onClickAddTask()}>
                    <FontAwesomeIcon icon={faAdd} />
                  </Button>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default GoalTemplateCard;
