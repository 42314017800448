import React, { useEffect, useRef, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import FormCheck from "../../../ui/FormCheckbox";
import SelectObject from "../../../ui/SelectObject";
import Textarea from "../../../ui/Textarea";
import SelectFamily from "./SelectFamily";
import ViewRecordings from "./ViewRecordings";
import DatePicker from "../../../ui/DatePicker";
import { User } from "../../../../types/user";

interface InterventionState {
  note?: any;
  family: User[];
  setUpdatedNote: any;
  setSaveIsEnabled: any;
  setUserToSave: any;
  myCase?: boolean;
  editNote?: boolean;
}

const Intervention = ({
  note,
  family,
  setUpdatedNote,
  setSaveIsEnabled,
  setUserToSave,
  myCase,
  editNote = false,
}: InterventionState) => {
  const mainRef = useRef();
  const [familyUser, setFamilyUser] = useState<string>("");
  const [referral, setReferral] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [performance, setPerformance] = useState<string>("");
  const [incomeRef, setIncomeRef] = useState<boolean>(false);
  const [educationRef, setEducationRef] = useState<boolean>(false);
  const [employmentRef, setEmploymentRef] = useState<boolean>(false);
  const [childcareRef, setChildcareRef] = useState<boolean>(false);
  const [healthRef, setHealthRef] = useState<boolean>(false);
  const [nutritionRef, setNutritionRef] = useState<boolean>(false);
  const [housingRef, setHousingRef] = useState<boolean>(false);
  const [transRef, setTransRef] = useState<boolean>(false);
  const [supportRef, setSupportRef] = useState<boolean>(false);
  const [familyRef, setFamilyRef] = useState<boolean>(false);
  const [outcome, setOutcome] = useState<string>("");
  const [plan, setPlan] = useState<string>("");
  const [noteDate, setNoteDate] = useState<string>("");

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  var referrals: any[] = [
    { id: 0, key: "case_worker", name: "Case Worker" },
    { id: 1, key: "client", name: "Client" },
    { id: 2, key: "community_partner", name: "Community Partner" },
    { id: 3, key: "other", name: "Other" },
  ];

  var performances: any[] = [
    { id: 0, key: "need_met", name: "Need Met" },
    { id: 1, key: "need_not_met", name: "Need Not Met" },
    { id: 1, key: "ineligible", name: "Ineligible" },
    { id: 2, key: "other", name: "Other" },
  ];

  var outcomes: any[] = [
    { id: 0, key: "yes", name: "Yes" },
    { id: 1, key: "no", name: "No" },
    { id: 2, key: "other", name: "Other" },
  ];

  useEffect(() => {
    handleScroll(mainRef);

    if (editNote) {
      setFamilyUser(note?.subject?.id + "|" + note?.subject?.type);
      setNoteDate(note?.fields?.date);
      setReferral(note.fields.who_completed);
      setClient(note.fields.who_referred_to);
      setPerformance(note.fields.performance);
      setIncomeRef(convertToBoolean(note.fields.nature_income));
      setEducationRef(convertToBoolean(note.fields.nature_education));
      setEmploymentRef(convertToBoolean(note.fields.nature_employment));
      setChildcareRef(convertToBoolean(note.fields.nature_childcare));
      setHealthRef(convertToBoolean(note.fields.nature_healthcare));
      setNutritionRef(convertToBoolean(note.fields.nature_nutrition));
      setHousingRef(convertToBoolean(note.fields.nature_housing));
      setTransRef(convertToBoolean(note.fields.nature_transportation));
      setSupportRef(convertToBoolean(note.fields.nature_support));
      setFamilyRef(convertToBoolean(note.fields.nature_family));
      setOutcome(note.fields.did_achieve);
      setPlan(note.fields.describe_plan);
    }
  }, []);

  const convertToBoolean = (field: any) => {
    return field === "0" ? false : true;
  };

  useEffect(() => {
    const parts = familyUser.split("|");

    const note = {
      notetype: "intervention",
      subject: {
        id: parts[0],
        type: parts[1],
      },
      fields: {
        date: noteDate,
        who_completed: referral,
        who_referred_to: client,
        performance: performance,
        nature_income: incomeRef,
        nature_education: educationRef,
        nature_employment: employmentRef,
        nature_childcare: childcareRef,
        nature_healthcare: healthRef,
        nature_nutrition: nutritionRef,
        nature_housing: housingRef,
        nature_transportation: transRef,
        nature_support: supportRef,
        nature_family: familyRef,
        did_achieve: outcome,
        describe_plan: plan,
      },
    };

    setUpdatedNote(note);
  }, [
    noteDate,
    referral,
    client,
    performance,
    incomeRef,
    educationRef,
    employmentRef,
    childcareRef,
    healthRef,
    nutritionRef,
    housingRef,
    transRef,
    supportRef,
    familyRef,
    outcome,
    plan,
    familyUser,
    setUpdatedNote,
  ]);

  const updateFamilyUser = (e: any) => {
    setFamilyUser(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateReferral = (e: any) => {
    setReferral(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateClient = (e: any) => {
    setClient(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updatePerformance = (e: any) => {
    setPerformance(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateIncomeRef = () => {
    setIncomeRef(!incomeRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateEducationRef = () => {
    setEducationRef(!educationRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateEmploymentRef = () => {
    setEmploymentRef(!employmentRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateChildcareRef = () => {
    setChildcareRef(!childcareRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateHealthRef = () => {
    setHealthRef(!healthRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateNutritionRef = () => {
    setNutritionRef(!nutritionRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateHousingRef = () => {
    setHousingRef(!housingRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateTransRef = () => {
    setTransRef(!transRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateSupportRef = () => {
    setSupportRef(!supportRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateFamilyRef = () => {
    setFamilyRef(!familyRef);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateOutcome = (e: any) => {
    setOutcome(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updatePlan = (e: any) => {
    setPlan(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateDate = () => {
    setSaveIsEnabled(true);
    setUserToSave(true);
  };

  useEffect(() => {
    if (!noteDate) {
      setSaveIsEnabled(false);
      setUserToSave(false);
    }
  }, [noteDate]);

  return (
    <>
      <Row className="mt-4" ref={mainRef}>
        <SelectFamily
          family={family}
          familyUser={familyUser}
          updateFamilyUser={updateFamilyUser}
          readOnly={myCase ? false : true}
        />
        <Col className="col-lg-4 col-md-6 col-sm-8">
          <DatePicker
            label="Date"
            currentValue={noteDate}
            setCurrentValue={setNoteDate}
            onUpdate={() => updateDate()}
            required={true}
            hasMaxDate={true}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      {note?.recordingsExist === true && <ViewRecordings note={note} editNote={editNote} myCase={myCase} />}
      <Row className="mt-3">
        <Col className="col-6">
          <SelectObject
            label="Who Completed the Referral?"
            value={referral}
            options={referrals}
            onUpdate={(e: any) => updateReferral(e)}
            disabled={myCase ? false : true}
          />
        </Col>
        <Col className="col-6">
          <FloatingLabel controlId="floatingInput" label="Who was the Client Referred to?">
            <Form.Control
              type="text"
              value={client}
              onChange={(e) => updateClient(e)}
              disabled={myCase ? false : true}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-6">
          <SelectObject
            label="What was the Performance of the Referral?"
            value={performance}
            options={performances}
            onUpdate={(e: any) => updatePerformance(e)}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <h6>What was the Nature of the Referral?</h6>
        <Col className="col-6">
          <FormCheck
            label="Income"
            value={incomeRef}
            onUpdate={(e: any) => updateIncomeRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Education"
            value={educationRef}
            onUpdate={(e: any) => updateEducationRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Employment"
            value={employmentRef}
            onUpdate={(e: any) => updateEmploymentRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Childcare"
            value={childcareRef}
            onUpdate={(e: any) => updateChildcareRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Healthcare & Health Status"
            value={healthRef}
            onUpdate={(e: any) => updateHealthRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Nutrition"
            value={nutritionRef}
            onUpdate={(e: any) => updateNutritionRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Housing"
            value={housingRef}
            onUpdate={(e: any) => updateHousingRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Transportation"
            value={transRef}
            onUpdate={(e: any) => updateTransRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Support Systems"
            value={supportRef}
            onUpdate={(e: any) => updateSupportRef()}
            disabled={myCase ? false : true}
          />
          <FormCheck
            label="Family Interactions"
            value={familyRef}
            onUpdate={(e: any) => updateFamilyRef()}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="col-12">
          <SelectObject
            label="If the referral provided the client services, did they achieve their desired outcome as a result?"
            value={outcome}
            options={outcomes}
            onUpdate={(e: any) => updateOutcome(e)}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Textarea
          label="If the referral did not provide services, describe plan to support achieving desired outcome."
          value={plan}
          onUpdate={(e: any) => updatePlan(e)}
          disabled={myCase ? false : true}
        />
      </Row>
    </>
  );
};

export default Intervention;
