import { faCheck, faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Col, Stack } from "react-bootstrap";
import { deleteUser } from "../../services/httpClient";
import ToastMsg from "../ui/ToastMsg";
import { User } from "../../types/user";

interface FamilyGuardState {
  user: User;
  family: any[];
  setFamily: any;
}

const FamilyGuardianCard = ({ user, family, setFamily }: FamilyGuardState) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  const onClickDelete = () => {
    setIsDeleting(true);
  };

  const onClickDeleteFinal = () => {
    const newFamily = family.filter((f: any) => f.id !== user.id);
    setFamily(newFamily);

    deleteUser(user?.id).then((res: any) => {
      setShowToast(true);
      setToastText("User Deleted");
      setToastBody(user?.firstname + " " + user?.lastname);
    });

    setIsDeleting(false);
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
  };

  const renderDelete = () => {
    return (
      <Col key={user.id} className="col-lg-3 col-md-4 col-sm-6 mb-2">
        <Card className="bg-light">
          <Card.Body>
            <Stack direction="horizontal" gap={3}>
              <Stack style={{ justifyContent: "center" }} gap={2}>
                <Card.Subtitle>Delete</Card.Subtitle>
                <Card.Subtitle>
                  {user?.firstname} {user?.lastname}?
                </Card.Subtitle>
              </Stack>
              <FontAwesomeIcon
                className="text-muted me-1 cursor-pointer"
                icon={faCheck}
                onClick={() => onClickDeleteFinal()}
              />
              <div className="vr" />
              <FontAwesomeIcon
                className="text-muted me-1 cursor-pointer"
                icon={faClose}
                onClick={() => onClickCancelDelete()}
              />
            </Stack>
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isDeleting ? (
        <>{renderDelete()}</>
      ) : (
        <Col key={user.id} xs={6} md={4} lg={3} className="mb-2">
          <Card key={user.firstname} className="bg-light">
            <Card.Body>
              <Stack direction="horizontal" gap={3}>
                <Stack gap={2}>
                  <Card.Subtitle>{user.firstname + " " + user.lastname}</Card.Subtitle>
                  <Card.Subtitle className={user.relationship ? "text-muted" : "text-muted pb-3"}>
                    {user.relationship ? user.relationship.charAt(0).toUpperCase() + user.relationship.slice(1) : " "}
                  </Card.Subtitle>
                </Stack>
                <FontAwesomeIcon
                  className="text-muted me-1 cursor-pointer"
                  icon={faTrash}
                  onClick={(e) => onClickDelete()}
                />
              </Stack>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

export default FamilyGuardianCard;
