import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import PageTitle from "../../ui/PageTitle";
import Messages from "./messages/Messages";
import { useParams } from "react-router-dom";
import { fetchUserCase } from "../../../services/httpClient";
import useApp from "../../../utils/useApp";

const MyCaseSupport = () => {
  const { id } = useParams();
  const { auth }: any = useApp();
  const [name, setName] = useState<string>();
  const [primaryCM, setPrimaryCM] = useState<any>();
  const [secondaryCM, setSecondaryCM] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [myCase, setMyCase] = useState<boolean>(false);

  //messages
  const [currentConversation, setCurrentConversation] = useState<any>({
    id: 0,
    name: "",
    participants: [auth?.profile.id],
  });

  useEffect(() => {
    setIsLoading(true);

    fetchUserCase(id)
      .then((res: any) => {
        setMyCase(true);
        setName(res[0].data.name);
        setPrimaryCM(res[0].data?.primaryCM);
        setSecondaryCM(res[0].data?.secondaryCM);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Container className={"md div-main custom-container"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={"md div-main custom-container"}>
          <PageTitle title={name} />
          <Row>
            <Col className="col-12">
              <Messages
                currentConversation={currentConversation}
                setCurrentConversation={setCurrentConversation}
                setNewMessage={false}
                primaryCM={primaryCM}
                secondaryCM={secondaryCM}
                getData={true}
                myCase={myCase}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default MyCaseSupport;
