import { faCheck, faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Col, Stack } from "react-bootstrap";
import { deleteGroupParticipant } from "../../services/httpClient";
import { GroupParticipant } from "../../types/groupparticipant";
import { useParams } from "react-router-dom";

const ParticipantCard = ({ item, getGroupParticipants }: any) => {
  const { id } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [participantToDelete, setParticipantToDelete] = useState<GroupParticipant>();

  const onClickDelete = (item: any) => {
    setIsDeleting(true);
    setParticipantToDelete(item);
  };

  const onClickDeleteFinal = () => {
    deleteGroupParticipant(id, participantToDelete?.id).then((res: any) => {
      setIsDeleting(false);
      setParticipantToDelete(undefined);
      getGroupParticipants();
    });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setParticipantToDelete(undefined);
  };

  const renderDelete = () => {
    return (
      <Col key={item.id} className="col-lg-3 col-md-4 col-sm-6 mb-3">
        <Card className="bg-light">
          <Card.Body>
            <Stack direction="horizontal" gap={3}>
              <Stack style={{ justifyContent: "center" }} gap={2}>
                <Card.Subtitle>Remove</Card.Subtitle>
                <Card.Subtitle>
                  {participantToDelete?.firstname} {participantToDelete?.lastname}?
                </Card.Subtitle>
              </Stack>
              <FontAwesomeIcon
                className="text-muted me-1 cursor-pointer"
                icon={faCheck}
                onClick={() => onClickDeleteFinal()}
              />
              <div className="vr" />
              <FontAwesomeIcon
                className="text-muted me-1 cursor-pointer"
                icon={faClose}
                onClick={() => onClickCancelDelete()}
              />
            </Stack>
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <>
      {isDeleting ? (
        <>{renderDelete()}</>
      ) : (
        <Col key={item.id} className="col-lg-3 col-md-4 col-sm-6 mb-3">
          <Card key={item.firstname} className="bg-light">
            <Card.Body>
              <Stack direction="horizontal" gap={3}>
                <Stack gap={2}>
                  <Card.Subtitle>{item.firstname + " " + item.lastname}</Card.Subtitle>
                  <Card.Subtitle className="text-muted">{item.relationship}</Card.Subtitle>
                </Stack>
                <FontAwesomeIcon
                  className="text-muted me-1 cursor-pointer"
                  icon={faTrash}
                  onClick={(e) => onClickDelete(item)}
                />
              </Stack>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

export default ParticipantCard;
