import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Textarea from "../../../ui/Textarea";
import FileUploadNotes from "./FileUploadNotes";
import SelectFamily from "./SelectFamily";
import { ViewNoteFiles } from "./ViewNoteFiles";
import { fetchNoteDocuments } from "../../../../services/httpClient";
import { useParams } from "react-router-dom";
import ViewRecordings from "./ViewRecordings";
import DatePicker from "../../../ui/DatePicker";
import { User } from "../../../../types/user";
import { Note } from "../../../../types/note";

interface StandardState {
  note?: Note;
  family: User[];
  documents: any;
  setDocuments: any;
  setUpdatedNote: any;
  setSaveIsEnabled: any;
  setUserToSave: any;
  myCase?: boolean;
  editNote?: boolean;
}

const Standard = ({
  note,
  family,
  documents,
  setDocuments,
  setUpdatedNote,
  setSaveIsEnabled,
  setUserToSave,
  myCase,
  editNote = false,
}: StandardState) => {
  const { id } = useParams();
  const mainRef = useRef();
  const [familyUser, setFamilyUser] = useState<string | null>();
  const [text, setText] = useState<string>("");
  const [noteDate, setNoteDate] = useState<string>("");

  const handleScroll = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    handleScroll(mainRef);
    console.log(note);
    if (editNote) {
      if (note?.subject) {
        setFamilyUser(note?.subject?.id + "|" + note?.subject?.type);
      }
      setNoteDate(note?.fields?.date);
      setText(note?.fields?.text);

      fetchNoteDocuments(id, note?.id).then((res: any) => {
        setDocuments(res[0].data);
      });
    }
  }, []);

  useEffect(() => {
    var note;
    if (familyUser) {
      const parts = familyUser.split("|");

      note = {
        notetype: "standard",
        subject: {
          id: parts[0],
          type: parts[1],
        },
        fields: {
          text: text,
          date: noteDate,
        },
      };
    } else {
      note = {
        notetype: "standard",
        subject: null,
        fields: {
          text: text,
          date: noteDate,
        },
      };
    }

    setUpdatedNote(note);
  }, [text, noteDate, familyUser, setUpdatedNote]);

  const updateFamilyUser = (e: any) => {
    if (e.target.value === "Select from the following") {
      setFamilyUser(null);
    } else {
      setFamilyUser(e.target.value);
    }

    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateText = (e: any) => {
    setText(e.target.value);
    if (noteDate) {
      setSaveIsEnabled(true);
      setUserToSave(true);
    }
  };

  const updateDate = () => {
    setSaveIsEnabled(true);
    setUserToSave(true);
  };

  useEffect(() => {
    if (!noteDate) {
      setSaveIsEnabled(false);
      setUserToSave(false);
    }
  }, [noteDate]);

  return (
    <>
      <Row className="mt-4" ref={mainRef}>
        <SelectFamily
          family={family}
          familyUser={familyUser}
          updateFamilyUser={updateFamilyUser}
          readOnly={myCase ? false : true}
        />
        <Col className="col-lg-4 col-md-6 col-sm-8">
          <DatePicker
            label="Date"
            currentValue={noteDate}
            setCurrentValue={setNoteDate}
            onUpdate={() => updateDate()}
            required={true}
            hasMaxDate={true}
            disabled={myCase ? false : true}
          />
        </Col>
      </Row>
      {note?.recordingsExist === true && <ViewRecordings note={note} editNote={editNote} myCase={myCase} />}
      <Row className="px-3 pb-3">
        <Textarea label="Notes" value={text} onUpdate={(e: any) => updateText(e)} disabled={myCase ? false : true} />
      </Row>
      <h5 className="px-3 app-header-text">Documents</h5>
      <Row className="px-3">
        {documents.length > 0 ? (
          <>
            <Col className="col-lg-6 col-sm-12 col-12">
              <ViewNoteFiles documents={documents} setDocuments={setDocuments} note={note} editNote={editNote} />
            </Col>
            {myCase && (
              <Col className="col-lg-6 col-sm-12 col-12">
                <FileUploadNotes
                  header="Upload Document"
                  documents={documents}
                  setDocuments={setDocuments}
                  note={note}
                  editNote={editNote}
                />
              </Col>
            )}
          </>
        ) : (
          myCase && (
            <Col className="col-lg-6 col-sm-12 col-12">
              <FileUploadNotes
                header="Upload Document"
                documents={documents}
                setDocuments={setDocuments}
                note={note}
                editNote={editNote}
              />
            </Col>
          )
        )}
      </Row>
    </>
  );
};

export default Standard;
