import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchAssessments } from "../../../../services/httpClient";
import { Assessment } from "../../../../types/assessment";
import ToastMsg from "../../../ui/ToastMsg";
import AssessmentContinuum from "./AssessmentContinuum";
import AssessmentWellBeing from "./AssessmentWellBeing";

interface AssessmentsState {
  viewAssessments: boolean;
  setViewAssessments: any;
  createAssessmentOne: boolean;
  setCreateAssessmentOne: any;
  createAssessmentTwo: boolean;
  setCreateAssessmentTwo: any;
  viewAssessmentOne: boolean;
  setViewAssessmentOne: any;
  viewAssessmentTwo: boolean;
  setViewAssessmentTwo: any;
  getData: boolean;
  myCase: boolean;
}

const Assessments = ({
  viewAssessments,
  setViewAssessments,
  createAssessmentOne,
  setCreateAssessmentOne,
  createAssessmentTwo,
  setCreateAssessmentTwo,
  viewAssessmentOne,
  setViewAssessmentOne,
  viewAssessmentTwo,
  setViewAssessmentTwo,
  getData,
  myCase,
}: AssessmentsState) => {
  const { id } = useParams();
  const [history, setHistory] = useState<Assessment[]>([]);
  const [results, setResults] = useState<any>();
  const [due, setDue] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getAssessments();
    }
  }, [getData]);

  const getAssessments = () => {
    fetchAssessments(id)
      .then((res: any) => {
        const assessments = res[0].data;

        setHistory(assessments);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    var dueDate = new Date();
    dueDate.setMonth(dueDate.getMonth() - 6);

    const historyByType = history.reduce((h: any, item: any) => {
      if (!h[item.assessmenttype]) {
        h[item.assessmenttype] = [];
      }

      h[item.assessmenttype].push(item);

      return h;
    }, {});

    if (history.length > 0) {
      for (let key in historyByType) {
        if (historyByType[key][0].created_at) {
          var currDate = new Date(historyByType[key][0].created_at);
          if (currDate <= dueDate) {
            setDue(true);
            break;
          } else {
            setDue(false);
          }
        }
      }
    }
  }, [history]);

  const updateViewAssessments = (e: any) => {
    setViewAssessments(e);
    setCreateAssessmentOne(false);
    setCreateAssessmentTwo(false);
    setViewAssessmentOne(false);
    setViewAssessmentTwo(false);
  };

  const updateCreateAssessmentOne = (e: any) => {
    setViewAssessments(false);
    setCreateAssessmentOne(e);
    setCreateAssessmentTwo(false);
    setViewAssessmentOne(false);
    setViewAssessmentTwo(false);
  };

  const updateCreateAssessmentTwo = (e: any) => {
    setViewAssessments(false);
    setCreateAssessmentOne(false);
    setCreateAssessmentTwo(e);
    setViewAssessmentOne(false);
    setViewAssessmentTwo(false);
  };

  const onClickViewAssessment = (item: any) => {
    setViewAssessments(false);
    setCreateAssessmentOne(false);
    setCreateAssessmentTwo(false);

    if (item.assessmenttype === "continuum") {
      setViewAssessmentOne(false);
      setViewAssessmentTwo(true);
    } else {
      setViewAssessmentOne(true);
      setViewAssessmentTwo(false);
    }

    setResults(item);
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          {viewAssessments === true && (
            <>
              <Row className="mb-0">
                <Col>
                  <h4 className="app-header-text">Assessments</h4>
                </Col>
                <Col className="text-end">
                  {myCase && (
                    <>
                      <Button className="app-primary-color me-3 button" onClick={() => updateCreateAssessmentOne(true)}>
                        Create Well Being
                      </Button>
                      <Button className="app-primary-color button" onClick={() => updateCreateAssessmentTwo(true)}>
                        Create Continuum
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              {due === true && (
                <Row>
                  <Alert key="warning" variant="warning" onClose={() => setDue(false)} dismissible>
                    An Assessment is Due!
                  </Alert>
                </Row>
              )}
              <Table striped>
                <thead>
                  <tr>
                    <th>Assessment Name</th>
                    <th>Completed Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {history?.map((h: any) => (
                    <tr key={h.id} className="cursor-pointer" onClick={(e: any) => onClickViewAssessment(h)}>
                      <td>
                        {h.assessmenttype +
                          " - " +
                          monthNames[new Date(h.created_at).getMonth()] +
                          " " +
                          new Date(h.created_at).getFullYear()}
                      </td>
                      <td>{new Date(h.created_at).toLocaleDateString()}</td>
                      <td className="text-end">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>View Assessment</Tooltip>}
                        >
                          <Button
                            className="me-3 app-primary-color button"
                            onClick={(e: any) => onClickViewAssessment(h)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}

          {createAssessmentOne === true && (
            <>
              <Row className="mb-0">
                <Col>
                  <h4 className="app-header-text">Well Being Assessment</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewAssessments(true)}>
                    Cancel
                  </Button>
                </Col>
              </Row>
              <AssessmentWellBeing
                setViewAssessments={setViewAssessments}
                setCreateAssessmentOne={setCreateAssessmentOne}
                setCreateAssessmentTwo={setCreateAssessmentTwo}
                fetchAssessments={getAssessments}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
              />
            </>
          )}

          {createAssessmentTwo === true && (
            <>
              <Row className="mb-0">
                <Col>
                  <h4 className="app-header-text">Independence Continuum</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewAssessments(true)}>
                    Cancel
                  </Button>
                </Col>
              </Row>
              <AssessmentContinuum
                setViewAssessments={setViewAssessments}
                setCreateAssessmentOne={setCreateAssessmentOne}
                setCreateAssessmentTwo={setCreateAssessmentTwo}
                fetchAssessments={getAssessments}
                setShowToast={setShowToast}
                setToastText={setToastText}
                setToastBody={setToastBody}
              />
            </>
          )}

          {viewAssessmentOne === true && (
            <>
              <Row className="mb-3">
                <Col>
                  <h4 className="app-header-text">Well Being Assessment</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewAssessments(true)}>
                    Back
                  </Button>
                </Col>
              </Row>
              <AssessmentWellBeing
                setViewAssessments={setViewAssessments}
                setCreateAssessmentOne={setCreateAssessmentOne}
                setCreateAssessmentTwo={setCreateAssessmentTwo}
                fetchAssessments={getAssessments}
                readOnly={true}
                results={results}
              />
            </>
          )}

          {viewAssessmentTwo === true && (
            <>
              <Row className="mb-3">
                <Col>
                  <h4 className="app-header-text">Independence Continuum</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => updateViewAssessments(true)}>
                    Back
                  </Button>
                </Col>
              </Row>
              <AssessmentContinuum
                setViewAssessments={setViewAssessments}
                setCreateAssessmentOne={setCreateAssessmentOne}
                setCreateAssessmentTwo={setCreateAssessmentTwo}
                fetchAssessments={getAssessments}
                readOnly={true}
                results={results}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Assessments;
