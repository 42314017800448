import { faAdd, faArchive, faCheckCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { deleteGoal, patchGoal, postTask } from "../../../../services/httpClient";
import { Goal } from "../../../../types/goal";
import Input from "../../../ui/Input";
import TaskItem from "./TaskItem";
import { Task } from "../../../../types/task";

interface GoalCardState {
  goal: Goal;
  fetchGoals?: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  myCase?: boolean;
}

const GoalCard = ({ goal, fetchGoals, setShowToast, setToastText, setToastBody, myCase }: GoalCardState) => {
  const { id } = useParams();

  const [isDeleting, setIsDeleting] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState<Goal>();
  const [newTask, setNewTask] = useState<string>("");
  const [showAddTask, setShowAddTask] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean | number>();
  const [archived, setArchived] = useState<boolean | number>();

  useEffect(() => {
    setCompleted(goal.is_completed);
    setArchived(goal.is_archived);
  }, []);

  const onClickAddTask = () => {
    setShowAddTask(true);
  };

  const onClickSaveTask = () => {
    const task = {
      name: newTask,
      is_next: false,
      is_completed: false,
      is_cm_reviewed: false,
      is_archived: false,
    };

    postTask(task, id, goal.id).then((res: any) => {
      setShowAddTask(false);
      setNewTask("");
      fetchGoals();
      setShowToast(true);
      setToastText("Task Created");
      setToastBody(newTask);
    });
  };

  const onClickCancelTask = () => {
    setShowAddTask(false);
  };

  const handleCompletedClick = () => {
    if (goal.is_archived !== 1 && goal.is_completed !== 1) {
      const goalChanges = {
        is_completed: !completed,
      };

      patchGoal(goalChanges, id, goal.id).then((res: any) => {
        setCompleted(!completed);
        fetchGoals();
        setShowToast(true);
        setToastText("Goal Completed");
        setToastBody(goal.name);
      });
    }
  };

  const handleArchivedClick = () => {
    const goalChanges = {
      is_archived: !archived,
    };

    patchGoal(goalChanges, id, goal.id).then((res: any) => {
      fetchGoals();
      setShowToast(true);
      setToastText(archived ? "Goal Unarchived" : "Goal Archived");
      setToastBody(goal.name);
      setArchived(!archived);
    });
  };

  const onClickDelete = () => {
    setIsDeleting(true);
    setGoalToDelete(goal);
  };

  const onClickDeleteFinal = () => {
    deleteGoal(id, goalToDelete?.id).then((res: any) => {
      setIsDeleting(false);
      setGoalToDelete(undefined);
      fetchGoals();
      setShowToast(true);
      setToastText("Goal Deleted");
      setToastBody(goalToDelete?.name);
    });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setGoalToDelete(undefined);
  };

  const onClickSubmit = (e: any) => {
    e?.preventDefault();
  };

  const renderDelete = () => {
    return (
      <Card className="bg-light mb-3">
        <Card.Body>
          <Stack direction="horizontal" gap={3}>
            <Stack style={{ justifyContent: "center" }} className="col-6">
              <Card.Subtitle>Are you sure you want to delete - {goalToDelete?.name}?</Card.Subtitle>
            </Stack>
            <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
              Confirm Delete
            </Button>
            <div className="vr" />
            <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
              Cancel
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {isDeleting ? (
        <>{renderDelete()}</>
      ) : (
        <Card key={goal.id} className="bg-light mb-3">
          <Card.Body>
            <Row>
              <Col className="col-9">
                <h5 className={goal.is_archived ? "text-muted" : "app-orange-text"}>{goal.name}</h5>
              </Col>
              <Col className="text-end">
                {myCase && (
                  <>
                    {goal.is_archived !== 1 && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Complete Goal</Tooltip>}
                      >
                        <FontAwesomeIcon
                          id="completed-button"
                          className={
                            completed ? "app-green-text me-3 cursor-pointer" : "text-muted me-3 cursor-pointer"
                          }
                          icon={faCheckCircle}
                          onClick={() => handleCompletedClick()}
                        />
                      </OverlayTrigger>
                    )}
                    {goal.is_completed !== 1 && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>{goal.is_archived === 1 ? "Unarchive Goal" : "Archive Goal"}</Tooltip>}
                      >
                        <FontAwesomeIcon
                          className={
                            archived ? "app-orange-text me-3 cursor-pointer" : "text-muted me-3 cursor-pointer"
                          }
                          icon={faArchive}
                          onClick={() => handleArchivedClick()}
                        />
                      </OverlayTrigger>
                    )}
                    {goal.is_archived !== 1 && goal.is_completed !== 1 && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Delete Goal</Tooltip>}
                      >
                        <FontAwesomeIcon
                          className="text-muted me-1 cursor-pointer"
                          icon={faTrash}
                          onClick={(e) => onClickDelete()}
                        />
                      </OverlayTrigger>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <h6>Tasks</h6>
            </Row>
            <Row className="mb-3">
              <ListGroup className="pe-0">
                {goal.tasks.map((t: Task) => (
                  <ListGroupItem key={t.id}>
                    <TaskItem
                      task={t}
                      goalArchived={goal.is_archived}
                      goalCompleted={goal.is_completed}
                      fetchGoals={fetchGoals}
                      goalId={goal.id}
                      setShowToast={setShowToast}
                      setToastText={setToastText}
                      setToastBody={setToastBody}
                      myCase={myCase}
                    />
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Row>
            {goal.is_archived !== 1 && goal.is_completed !== 1 && (
              <>
                {showAddTask ? (
                  <>
                    <Form onSubmit={(e: any) => onClickSubmit(e)}>
                      <Row>
                        <Col xs={12}>
                          <Stack direction="horizontal" gap={3}>
                            <Stack style={{ justifyContent: "center" }} className="col-6">
                              <Input
                                label="Enter New Task"
                                type="text"
                                value={newTask}
                                hasFocus={true}
                                onUpdate={(e: any) => setNewTask(e.target.value)}
                              />
                            </Stack>
                            <Button
                              type="submit"
                              className="app-secondary-color button"
                              onClick={() => onClickSaveTask()}
                            >
                              Save
                            </Button>
                            <div className="vr" />
                            <Button className="app-primary-color button" onClick={() => onClickCancelTask()}>
                              Cancel
                            </Button>
                          </Stack>
                        </Col>
                      </Row>
                    </Form>
                  </>
                ) : (
                  <Row>
                    <Col className="col-fill"></Col>
                    <Col className="text-end">
                      {goal.goal_template_id ? (
                        <Badge className="app-primary-color-inverse" bg="none">
                          From Template
                        </Badge>
                      ) : (
                        myCase && (
                          <Button className="app-tertiary-color button" onClick={() => onClickAddTask()}>
                            <FontAwesomeIcon icon={faAdd} />
                          </Button>
                        )
                      )}
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default GoalCard;
