import { faBullseye, faClipboardCheck, faClipboardQuestion, faFileText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import PageTitle from "../ui/PageTitle";
import GoalsAndTasksTab from "./GoalsAndTasksTab";
import InteractionTab from "./InteractionTab";
import WellBeingTab from "./WellBeingTab";
import ContinuumTab from "./ContinuumTab";
import useApp from "../../utils/useApp";
import { trackNavigationEvent } from "../../utils/analytics";
import jsPDF from "jspdf";

const Reports = () => {
  const { showMenu }: any = useApp();
  const [activeInteractions, setActiveInteractions] = useState<boolean>(false);
  const [activeGoalsAndTasks, setActiveGoalsAndTasks] = useState<boolean>(false);
  const [activeWellBeing, setActiveWellBeing] = useState<boolean>(false);
  const [activeContinuum, setActiveContinuum] = useState<boolean>(false);

  useEffect(() => {
    setActiveInteractions(true);
    setActiveGoalsAndTasks(false);
    setActiveWellBeing(false);
    setActiveContinuum(false);

    trackNavigationEvent("switch_view", "interaction_reports");
  }, []);

  const onNavClick = (route: string) => {
    if (route) {
      trackNavigationEvent("switch_view", route + "_reports");
    }

    switch (route) {
      case "interaction":
        setActiveInteractions(true);
        setActiveGoalsAndTasks(false);
        setActiveWellBeing(false);
        setActiveContinuum(false);
        break;
      case "goals":
        setActiveInteractions(false);
        setActiveGoalsAndTasks(true);
        setActiveWellBeing(false);
        setActiveContinuum(false);
        break;
      case "wellbeing":
        setActiveInteractions(false);
        setActiveGoalsAndTasks(false);
        setActiveWellBeing(true);
        setActiveContinuum(false);
        break;
      case "continuum":
        setActiveInteractions(false);
        setActiveGoalsAndTasks(false);
        setActiveWellBeing(false);
        setActiveContinuum(true);
        break;
      case "general":
        setActiveInteractions(false);
        setActiveGoalsAndTasks(false);
        setActiveWellBeing(false);
        setActiveContinuum(false);
        break;
      default:
        setActiveInteractions(false);
        setActiveGoalsAndTasks(false);
        setActiveWellBeing(false);
        setActiveContinuum(false);
        break;
    }
  };

  const [canvas, setCanvas] = useState<any>();

  function buildPDF(type: string) {
    try {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const margin = 10;
      const headerHeight = 20; // Adjust as needed
      const pageHeight = pdf.internal.pageSize.getHeight();
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

      let yPosition = margin + headerHeight;

      // Add header to the first page
      const headerImageUrl = "/AndGo_Logo_Color_Horizontal.png";
      pdf.addImage(headerImageUrl, "PNG", margin, margin, 40, 10); // Adjust dimensions and position as needed
      pdf.setFontSize(12);
      pdf.text(type + " Report", margin + 45, margin + 7); // Adjust text position as needed

      // Helper function to crop canvas
      const cropCanvas = (sourceCanvas: any, cropX: number, cropY: number, cropWidth: number, cropHeight: number) => {
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = cropWidth;
        tempCanvas.height = cropHeight;
        const ctx = tempCanvas.getContext("2d");
        ctx?.drawImage(sourceCanvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
        return tempCanvas;
      };

      // Check if the content fits on one page
      if (imgHeight <= pageHeight - yPosition) {
        pdf.addImage(imgData, "PNG", margin, yPosition, pdfWidth, imgHeight);
      } else {
        // Handle multi-page case
        let remainingHeight = canvas.height;
        let position = 0;
        const pageCanvasHeight = 1800;

        while (remainingHeight > 0) {
          const pageCanvas = cropCanvas(canvas, 0, position, canvas.width, pageCanvasHeight);
          const pageImgData = pageCanvas.toDataURL("image/png");
          pdf.addImage(pageImgData, "PNG", margin, yPosition, pdfWidth, pageCanvasHeight * (pdfWidth / canvas.width));

          remainingHeight -= pageCanvasHeight;
          position += pageCanvasHeight;

          if (remainingHeight > 0) {
            pdf.addPage();
            yPosition = margin + headerHeight;
            pdf.addImage(headerImageUrl, "PNG", margin, margin, 40, 10);
            pdf.text(type + " Report", margin + 45, margin + 7);
          }
        }
      }

      // const currentDate = new Date().toDateString();
      const formattedDate = new Date()
        .toISOString()
        .replace(/[-:.TZ]/g, "")
        .slice(0, 14);

      pdf.save(type + "_" + formattedDate + ".pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }

  return (
    <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
      <Tab.Container defaultActiveKey="interaction">
        <Row>
          <Col xs={12} lg={3}>
            <Nav
              variant="pills"
              className={
                showMenu
                  ? "flex-column sticky-top mycase-menu-open-sticky"
                  : "flex-column sticky-top mycase-menu-sticky"
              }
            >
              <Nav.Item className="mb-2">
                <PageTitle title={"Reports"} />
              </Nav.Item>
              <Nav.Item className="d-grid">
                <Nav.Link
                  eventKey="interaction"
                  className={activeInteractions ? "nav-vertical-active" : "nav-vertical"}
                  onClick={() => onNavClick("interaction")}
                >
                  <>
                    <FontAwesomeIcon icon={faFileText} className={"me-2"} />
                    {"Interactions"}
                  </>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="d-grid">
                <Nav.Link
                  eventKey="goals"
                  className={activeGoalsAndTasks ? "nav-vertical-active" : "nav-vertical"}
                  onClick={() => onNavClick("goals")}
                >
                  <>
                    <FontAwesomeIcon icon={faBullseye} className={"me-2"} />
                    {"Goals and Tasks"}
                  </>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="d-grid">
                <Nav.Link
                  eventKey="wellbeing"
                  className={activeWellBeing ? "nav-vertical-active" : "nav-vertical"}
                  onClick={() => onNavClick("wellbeing")}
                >
                  <FontAwesomeIcon icon={faClipboardQuestion} className={"me-2"} />
                  {"Well Being"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="d-grid">
                <Nav.Link
                  eventKey="continuum"
                  className={activeContinuum ? "nav-vertical-active" : "nav-vertical"}
                  onClick={() => onNavClick("continuum")}
                >
                  <>
                    <FontAwesomeIcon icon={faClipboardCheck} className={"me-2"} />
                    {"Continuum"}
                  </>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs={12} lg={9} className="mt-2">
            <Tab.Content>
              <Tab.Pane eventKey="interaction">
                <InteractionTab buildPDF={() => buildPDF("Interactions")} canvas={canvas} setCanvas={setCanvas} />
              </Tab.Pane>
              <Tab.Pane eventKey="goals">
                <GoalsAndTasksTab buildPDF={() => buildPDF("Goals/Tasks")} canvas={canvas} setCanvas={setCanvas} />
              </Tab.Pane>
              <Tab.Pane eventKey="wellbeing">
                <WellBeingTab buildPDF={() => buildPDF("Wellbeing")} canvas={canvas} setCanvas={setCanvas} />
              </Tab.Pane>
              <Tab.Pane eventKey="continuum">
                <ContinuumTab buildPDF={() => buildPDF("Continuum")} canvas={canvas} setCanvas={setCanvas} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default Reports;
