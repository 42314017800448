import React, { useEffect } from "react";
import useApp from "../../utils/useApp";
import { Badge, Col, Container, Row } from "react-bootstrap";
import PeopleCard from "./PeopleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface PeopleViewState {
  allPeople: any[];
  myPeople: any[];
  filteredAllPeople: any[];
  setFilteredAllPeople: any;
  filteredMyPeople: any[];
  setFilteredMyPeople: any;
  clearFilters: boolean;
  banFilterClicked: boolean;
  setBanFilterClicked: any;
  setSearchText: any;
}

const PeopleView = ({
  allPeople,
  myPeople,
  filteredAllPeople,
  setFilteredAllPeople,
  filteredMyPeople,
  setFilteredMyPeople,
  clearFilters,
  banFilterClicked,
  setBanFilterClicked,
  setSearchText,
}: PeopleViewState) => {
  const { viewAllCases }: any = useApp();

  const onClickClearFilters = () => {
    setFilteredMyPeople(myPeople);
    setFilteredAllPeople(allPeople);
    setBanFilterClicked(false);
    setSearchText("");
  };

  useEffect(() => {
    console.log("3");
    setBanFilterClicked(false);
  }, [clearFilters]);

  return (
    <>
      <Row className="mt-3">
        {banFilterClicked && (
          <Col className="col-12 text-center">
            <Badge className="app-tertiary-color-inverse" bg="none">
              Banned
            </Badge>
            <Badge className="app-tertiary-color ms-1" bg="none" onClick={onClickClearFilters}>
              <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
            </Badge>
          </Col>
        )}
      </Row>
      <Row>
        <Col></Col>
        <Col xs={9}>
          <hr className="mt-3" />
        </Col>
        <Col></Col>
      </Row>
      {viewAllCases ? (
        <Row className="justify-content-center mb-5">
          <Container className="card-container">
            {filteredAllPeople.map((p: any, index) => (
              <PeopleCard key={index} person={p} />
            ))}
          </Container>
        </Row>
      ) : (
        <Row className="justify-content-center mb-5">
          <Container className="card-container">
            {filteredMyPeople.map((p: any, index) => (
              <PeopleCard key={index} person={p} />
            ))}
          </Container>
        </Row>
      )}
      <Row className="my-5"></Row>
    </>
  );
};

export default PeopleView;
