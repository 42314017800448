import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, FloatingLabel, Button, Spinner } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { fetchGroup, fetchGroupParticipants, postNote } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import ToastMsg from "../ui/ToastMsg";
import GroupMeetingIndivNote from "./GroupMeetingIndivNote";
import DatePicker from "../ui/DatePicker";
import useApp from "../../utils/useApp";
import SaveChangesModal from "../ui/SaveChangesModal";
import { unsavedChanges } from "../../App";

const GroupMeeting = () => {
  const { id } = useParams();
  const { showMenu }: any = useApp();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [participants, setParticipants] = useState<any[]>([]);
  const [groupNote, setGroupNote] = useState<string>("");
  const [meetingDate, setMeetingDate] = useState<string>("");
  // const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [enableSave, setEnableSave] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [toastDisplayed, setToastDisplayed] = useState(false);

  let navigate = useNavigate();

  const backGroup = () => {
    if (enableSave || unsavedChanges.value === true) {
      setShowModal(true);
    } else {
      navigate(`/groups`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getGroup();
  }, [id]);

  const getGroup = () => {
    fetchGroup(id)
      .then((res: any) => {
        const currentGroup = res[0].data;
        setName(currentGroup.name);
        setDescription(currentGroup.description);

        fetchGroupParticipants(id).then((res: any) => {
          const currentParts = res[0].data;
          var parts: any[] = [];
          currentParts.map((c: any) => {
            const part = {
              user_id: c?.participant_id,
              type: c?.participant_type,
              firstname: c?.firstname,
              lastname: c?.lastname,
              case_id: c?.caseid,
              note: "",
              is_present: true,
            };

            parts.push(part);
          });

          setParticipants(parts);
          setIsLoading(false);
        });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const createNotes = () => {
    setIsLoading(true);
    const filteredParticipants = participants.filter((p: any) => p.is_present === true);

    filteredParticipants.map((p: any) => {
      const newNote = {
        notetype: "group",
        subject: {
          id: p.user_id,
          type: p.type,
        },
        fields: {
          group_name: name,
          group_note: groupNote,
          individual_note: p.note,
          date: meetingDate,
        },
      };

      postNote(newNote, p.case_id).then((res: any) => {
        const noteId = res[0].data.note_id;
        console.log(noteId);
        if (toastDisplayed === false) {
          setShowToast(true);
          setToastText("Group Notes Created");
          setToastBody(name);
          setToastDisplayed(true);
        }
      });
    });

    setTimeout(() => {
      setIsLoading(false);
      unsavedChanges.value = false;
      navigate(`/groups`);
    }, 1500);
  };

  const updateGroupNote = (e: any) => {
    setGroupNote(e.target.value);
    unsavedChanges.value = true;

    if (meetingDate) {
      setEnableSave(true);
    }
  };

  useEffect(() => {
    if (meetingDate) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [meetingDate]);

  useEffect(() => {
    if (enableSave) {
      unsavedChanges.value = true;
    } else {
      unsavedChanges.value = false;
    }
  }, [enableSave]);

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          {/* <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p> */}
          <Row className="mb-3">
            <Col xs={12} className="text-start">
              <Button className={"app-primary-color button"} onClick={(e) => backGroup()}>
                {"Back to Groups"}
              </Button>
            </Col>
          </Row>
          <PageTitle title={name} />
          <h6 className="text-muted mb-4">{description}</h6>
          <Row>
            <Col xs={12} lg={6}>
              <h6 className="app-header-text mt-2">Participants</h6>
              {participants.map((p: any, index: number) => (
                <GroupMeetingIndivNote
                  participant={p}
                  index={index}
                  participants={participants}
                  setParticipants={setParticipants}
                  setEnableSave={setEnableSave}
                  meetingDate={meetingDate}
                />
              ))}
            </Col>
            <Col xs={12} lg={6}>
              <h6 className="app-header-text mt-2">Meeting</h6>
              <FloatingLabel controlId="floatingInput" label="Group Note" className="sm mb-2 mt-1">
                <Form.Control
                  type="text"
                  placeholder="Group Note"
                  value={groupNote}
                  onChange={(e) => updateGroupNote(e)}
                />
              </FloatingLabel>
              <Col xs={6} md={4}>
                <DatePicker
                  label="Date"
                  currentValue={meetingDate}
                  setCurrentValue={setMeetingDate}
                  required={true}
                  hasMaxDate={true}
                />
              </Col>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={4}>
              <Button className="app-secondary-color me-3 button" onClick={(e) => createNotes()} disabled={!enableSave}>
                {"Save Notes"}
              </Button>
            </Col>
          </Row>
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/groups" />
        </Container>
      )}
    </>
  );
};

export default GroupMeeting;
