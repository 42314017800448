import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchConversations, postConversation } from "../../../../services/httpClient";
import Input from "../../../ui/Input";
import CreateGroupUser from "./CreateGroupUser";
import { User } from "../../../../types/user";

interface CreateGroupState {
  conversations: any[];
  setConversations: any;
  setShowConversation: any;
  setCurrentConversation: any;
  users: User[];
  checkedUsers: User[];
  setCheckedUsers: any;
  getPossibleUsers: any;
}

const CreateGroup = ({
  conversations,
  setConversations,
  setShowConversation,
  setCurrentConversation,
  users,
  checkedUsers,
  setCheckedUsers,
  getPossibleUsers,
}: CreateGroupState) => {
  const { id } = useParams();
  const [checkedUserIds, setCheckedUserIds] = useState<any[]>([]);
  const [groupName, setGroupName] = useState<string>("");
  const [isExisting, setIsExisting] = useState<boolean>(false);

  const updateGroupName = (e: any) => {
    setGroupName(e.target.value);
  };

  const onClickCreateGroup = () => {
    const newGroup = {
      name: groupName,
      participants: checkedUserIds,
    };

    postConversation(newGroup, id)
      .then((res: any) => {
        getConversations(res[0].data.conversation_id);
        setGroupName("");
        setCheckedUsers([]);
        getPossibleUsers();
        setShowConversation(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getConversations = (convId: any) => {
    fetchConversations(id)
      .then((res: any) => {
        const convList = res[0].data;
        setConversations(convList);
        const current = convList.filter((c: any) => c.id === convId);
        setCurrentConversation(current[0]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    isExistingGroup();
  }, [checkedUsers]);

  function isExistingGroup() {
    var isExisting = false;

    var currConvUserIds: any[] = [];
    conversations.map((c: any) => {
      var arr: any[] = [];
      c.participants.map((p: any) => {
        arr.push(p.id);
      });
      currConvUserIds.push(arr);
    });

    const checkedUserIds: number[] = [];
    checkedUsers.map((c: any, index: any) => {
      checkedUserIds.push(c.user_id);
    });
    setCheckedUserIds(checkedUserIds);

    currConvUserIds = currConvUserIds.filter((c: any) => {
      return c.length === checkedUserIds.length;
    });

    let checker = (arr: any, target: any) => target.every((v: any) => arr.includes(v));

    currConvUserIds.map((m: any) => {
      if (checker(m, checkedUserIds) === true) {
        isExisting = true;
      }
    });

    setIsExisting(isExisting);
    return isExisting;
  }

  return (
    <>
      <div className="message-header">
        <Row className="justify-content-center pt-3">
          <Col className="col-8 text-center">
            <h6 className="app-orange-text">Create New Thread</h6>
          </Col>
        </Row>
      </div>
      <div className="pb-3">
        <Row className="justify-content-center">
          <Col className="col-8">
            <Input
              label="Enter Name"
              type="text"
              value={groupName}
              onUpdate={(e: any) => updateGroupName(e)}
              required={true}
              hasFocus={true}
            />
          </Col>
        </Row>
      </div>
      <div className="message-body mx-5">
        <Container>
          <Row className="justify-content-center pb-3 pt-3">
            <Col className="col-12">
              <ListGroup>
                {users?.map((m: any, index: any) => (
                  <CreateGroupUser key={index} user={m} checkedUsers={checkedUsers} setCheckedUsers={setCheckedUsers} />
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>
      {isExisting && (
        <div>
          <h6 className="app-orange-text text-center pt-3">Thread Exists</h6>
        </div>
      )}
      <div className="message-footer">
        <Row className="pb-2 pt-2">
          <Col className="text-center">
            <Button
              className="app-primary-color button"
              onClick={() => onClickCreateGroup()}
              disabled={isExisting || checkedUsers.length === 0 || !groupName}
            >
              <FontAwesomeIcon icon={faComments} className="pe-1" />
              Create Thread
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CreateGroup;
