import React, { useEffect, useState } from "react";
import { Button, Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import SelectObject from "../../../ui/SelectObject";
import { Goal } from "../../../../types/goal";
import { fetchGoalTemplates, postGoal, postTask } from "../../../../services/httpClient";
import useApp from "../../../../utils/useApp";
import { Task } from "../../../../types/task";
import { useParams } from "react-router-dom";

const UseGoalTemplate = ({ setShowToast, setToastText, setToastBody }: any) => {
  const { id } = useParams();
  const { auth }: any = useApp();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("0");
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [templates, setTemplates] = useState<Goal[]>();

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = () => {
    fetchGoalTemplates(auth?.profile?.userOrgID)
      .then((res: any) => {
        var temps = res[0].data;
        console.log(temps);
        temps = temps.map((t: any) => {
          return { ...t, key: t.id };
        });
        console.log(temps);
        setTemplates(temps);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateTemplate = (e: any) => {
    console.log(e.target.value);
    setSelectedTemplateId(e.target.value);
    const selected = templates?.filter((t: any) => {
      return t?.id == e.target.value;
    });
    setSelectedTemplate(selected);
    console.log(selected);
  };

  const onClickCreateGoal = () => {
    const selected = selectedTemplate[0];
    const newGoal = {
      name: selected.name,
      is_completed: false,
      is_archived: false,
      goal_template_id: selected.id,
    };

    postGoal(newGoal, id).then((res: any) => {
      const response = res[0].data;
      console.log(response);

      selected.tasks?.map((t: any) => {
        const newTask = {
          name: t?.name,
          is_next: false,
          is_completed: false,
          is_cm_reviewed: false,
          is_archived: false,
        };

        postTask(newTask, id, response?.goal_id).then((res: any) => {});
      });

      setSelectedTemplateId("0");
      setSelectedTemplate(null);
      setShowToast(true);
      setToastText("Goal Created");
      setToastBody(selectedTemplate[0].name);
    });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <SelectObject
            label="Goal Template"
            value={selectedTemplateId}
            options={templates}
            onUpdate={(e: any) => updateTemplate(e)}
          />
        </Col>
      </Row>
      {selectedTemplateId !== "0" && (
        <>
          <Row>
            <Card className="bg-light mb-3">
              <Card.Body>
                <Row className="mb-3">
                  <Col xs={12}>
                    <h5 className="app-orange-text ms-3">{selectedTemplate[0]?.name}</h5>
                  </Col>
                </Row>
                <Row>
                  <h6 className="ps-3 ms-3">Tasks</h6>
                </Row>
                <Row className="mb-2">
                  <ListGroup className="pe-0">
                    {selectedTemplate[0]?.tasks?.map((t: Task) => (
                      <ListGroupItem key={t?.id} className="mb-2 mx-3">
                        <Row>
                          <Col className="col-11">{t?.name}</Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Row>
              </Card.Body>
            </Card>
            <Row>
              <Col className="text-center">
                <Button className="button app-primary-color" onClick={() => onClickCreateGoal()}>
                  Create Goal From Template
                </Button>
              </Col>
            </Row>
          </Row>
        </>
      )}
    </>
  );
};

export default UseGoalTemplate;
