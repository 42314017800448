import React from "react";
import { Col, FloatingLabel, Form } from "react-bootstrap";
import { User } from "../../../../types/user";

interface SelectFamilyState {
  defaultSelection?: string;
  family: User[];
  familyUser: any;
  updateFamilyUser: any;
  readOnly?: boolean;
}

const SelectFamily = ({
  defaultSelection = "Select from the following",
  family,
  familyUser,
  updateFamilyUser,
  readOnly = false,
}: SelectFamilyState) => {
  return (
    <Col className="col-6">
      <FloatingLabel controlId="floatingInput" label={"Associated Family Member"}>
        <Form.Select
          className="decorated"
          value={familyUser}
          onChange={(e: any) => updateFamilyUser(e)}
          disabled={readOnly}
        >
          <option key={0}>{defaultSelection}</option>
          {family.map((i: any, index: any) => (
            <option key={index} datatype={i.type} value={i.id + "|" + i.type}>
              {i.name}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Col>
  );
};

export default SelectFamily;
