import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Col, ListGroupItem, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import { Task } from "../../../types/task";
import useApp from "../../../utils/useApp";
import { unsavedChanges } from "../../../App";
import { deleteTaskTemplate, patchTaskTemplate } from "../../../services/httpClient";
import Input from "../../ui/Input";

interface TaskTemplateState {
  t: Task;
  goalId: string;
  getGoalTemplates: any;
  isGoalEditing: boolean;
  isTaskEditing: boolean;
  setIsTaskEditing: any;
  showAddTask: boolean;
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const TaskTemplateItem = ({
  t,
  goalId,
  getGoalTemplates,
  isGoalEditing,
  isTaskEditing,
  setIsTaskEditing,
  showAddTask,
  setOrgToSave,
  setShowToast,
  setToastText,
  setToastBody,
}: TaskTemplateState) => {
  const { auth }: any = useApp();
  const [taskName, setTaskName] = useState<string>(t?.name);
  const [isDeleting, setIsDeleting] = useState(false);
  const [allowSaveTask, setAllowSaveTask] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);

  const updateTask = (e: any) => {
    setTaskName(e.target.value);
    setAllowSaveTask(true);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickEdit = () => {
    setIsTaskEditing(true);
    setIsEditing(true);
  };

  const onClickDelete = () => {
    setIsDeleting(true);
  };

  const onClickDeleteFinal = () => {
    deleteTaskTemplate(auth?.profile?.userOrgID, goalId, t?.id).then((res: any) => {
      setAllowSaveTask(false);
      unsavedChanges.value = false;
      setShowToast(true);
      setToastText("Task Deleted");
      setToastBody(t.name);
      getGoalTemplates();
    });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
  };

  const onClickSave = () => {
    const updatedTask = {
      name: taskName,
    };

    patchTaskTemplate(updatedTask, auth?.profile?.userOrgID, goalId, t?.id).then((res: any) => {
      setAllowSaveTask(false);
      unsavedChanges.value = false;
      setOrgToSave(false);
      setIsTaskEditing(false);
      setIsEditing(false);
      getGoalTemplates();
      setShowToast(true);
      setToastText("Task Updated");
      setToastBody(taskName);
    });
  };

  const onClickCancel = () => {
    setIsTaskEditing(false);
    unsavedChanges.value = false;
    setOrgToSave(false);
    setIsEditing(false);
  };

  const renderDelete = () => {
    return (
      <ListGroupItem key={t?.id} className="mb-2 mx-3">
        <Stack direction="horizontal" gap={3}>
          <Stack style={{ justifyContent: "center" }} className="col-6">
            <Card.Subtitle>Are you sure you want to delete - {t?.name}?</Card.Subtitle>
          </Stack>
          <Button className="app-danger-color button" onClick={() => onClickDeleteFinal()}>
            Confirm Delete
          </Button>
          <div className="vr" />
          <Button className="app-primary-color button" onClick={() => onClickCancelDelete()}>
            Cancel
          </Button>
        </Stack>
      </ListGroupItem>
    );
  };

  return (
    <>
      {isDeleting ? (
        <>{renderDelete()}</>
      ) : (
        <ListGroupItem key={t?.id} className="mb-2 mx-3">
          {isEditing ? (
            <Stack direction="horizontal" gap={3}>
              <Stack style={{ justifyContent: "center" }} className="col-6">
                <Input label="Task" type="text" value={taskName} onUpdate={(e: any) => updateTask(e)} />
              </Stack>
              <Button className="button app-secondary-color" onClick={() => onClickSave()} disabled={!allowSaveTask}>
                Save
              </Button>
              <div className="vr" />
              <Button className="button app-primary-color" onClick={() => onClickCancel()}>
                Cancel
              </Button>
            </Stack>
          ) : (
            <Row>
              <Col xs={10}>{t?.name}</Col>
              <Col xs={2} className=" align-self-center p-0 text-end">
                {isGoalEditing || isTaskEditing || showAddTask ? (
                  <FontAwesomeIcon className={"app-light-gray-text me-3"} icon={faEdit} />
                ) : (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Edit Task</Tooltip>}
                  >
                    <FontAwesomeIcon
                      className={"text-muted me-3 cursor-pointer"}
                      icon={faEdit}
                      onClick={(e) => onClickEdit()}
                    />
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Delete Task</Tooltip>}
                >
                  <FontAwesomeIcon
                    className="text-muted cursor-pointer me-3"
                    icon={faTrash}
                    onClick={(e) => onClickDelete()}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          )}
        </ListGroupItem>
      )}
    </>
  );
};

export default TaskTemplateItem;
